import generateSessionReport from '@/modules/memogenius-game/generateSessionReport';

import questionService from './question-service';
import getLevelOptions from './get-level-options';

export default class Session {
    constructor(options) {
        if (options.levelIndex !== undefined) {
            this.levelIndex = options.levelIndex;
            this.options = getLevelOptions(this.levelIndex);
        } else {
            this.options = options;
        }
    }
    generateMemorize() {
        const { numberOfItems, itemMemorizeTimeLimit, language } = this.options;
        const memorizeTimeLimit = itemMemorizeTimeLimit * numberOfItems;
        let items;
        if (this.options.populated) {
            items = this.options.items;
        } else {
            items = questionService.getItems(numberOfItems, {
                language
            });
        }

        return {
            items: items.map(xx => {
                return {
                    memorizeItemType: 'GameVocabguru-MemorizeItem',
                    data: xx,
                    timeLimit: itemMemorizeTimeLimit
                };
            }),
            timeLimit: memorizeTimeLimit,
            timeoutPerItem: true
        };
    }
    reportMemorize({ items, timeUsed }) {
        this.memorizeReport = { items, timeUsed };
    }
    generateQuiz() {
        const { numberOfItems, itemQuizTimeLimit } = this.options;
        const quizTimeLimit = itemQuizTimeLimit * numberOfItems;

        const items = this.memorizeReport ? this.memorizeReport.items : this.generateMemorize().items;

        const sourceItems = items.map((item, i) => {
            return {
                quizItemType: 'GameVocabguru-QuizItem',
                data: item.data,
                memorizeTime: item.timeUsed
            };
        });

        return {
            sourceItems,
            items: [],
            timeLimit: quizTimeLimit,
            lives: 3
        };
    }
    reportQuiz({ items, timeUsed, score, sourceItems }) {
        const { numberOfItems, stars2, stars3 } = this.options;
        const memorizeTime = this.memorizeReport && this.memorizeReport.timeUsed;
        let stars = 0;
        // NOTE: should not give stars if score < 1. But score wrongely reported, so ignored
        if (memorizeTime) {
            if (memorizeTime < stars3 * numberOfItems * 1000) {
                stars = 3;
            } else if (memorizeTime < stars2 * numberOfItems * 1000) {
                stars = 2;
            } else {
                stars = 1;
            }
        }

        this.quizReport = { items, timeUsed, score, sourceItems, stars };
    }
    generateReport() {
        return generateSessionReport({
            memorizeReport: this.memorizeReport,
            quizReport: this.quizReport,
            initial(item, sourceItem) {
                return { type: 'word', value: sourceItem.data.b.word };
            },
            attempt(test) {
                return {
                    type: 'word',
                    value: test.submittedAnswer,
                    score: test.score
                };
            }
        });
    }

    generateSessionOptionsForRetry() {
        return Object.assign({}, this.options, {});
        // const items = []
        // const doneMap = {}
        //
        // this.quizReport.items.forEach(item => {
        //   if (item.score === 1) {
        //     doneMap[item.sourceItemIndex] = true
        //   }
        // })
        //
        // this.quizReport.sourceItems.forEach((sourceItem, i) => {
        //   if (!doneMap[i]) {
        //     items.push(sourceItem.data)
        //   }
        // })
        //
        // return Object.assign({}, this.options, {
        //   populated: true,
        //   items,
        //   numberOfItems: items.length
        // })
    }
}
