export default [
    ['Nore', 'Above', 'Ovenfor'],
    ['Ee-Ah', 'Abyss', 'Et dyp'],
    ['Rooth', 'Anger', 'Sinne'],
    ['Rahnk', 'Arm', 'Arm'],
    ['Dahg-Near', 'Bane', 'Bane'],
    ['Dah-Gore', 'Battle', 'Kamp'],
    ['Bane', 'Beautiful', 'Vakker'],
    ['Ah-Dell', 'Behind', 'Bak'],
    ['Nehll', 'Bell', 'Bjelle'],
    ['New-In', 'Beneath', 'Nedenfor'],
    ['Ah-Thahn', 'Beyond', 'Bortenfor'],
    ['Breh-Theel', 'Birch', 'Bjørk'],
    ['More', 'Black', 'Svart'],
    ['Sare-Egg', 'Blood', 'Blod'],
    ['Lohth', 'Blossom', 'Blossom'],
    ['Loo-In', 'Orange', 'Orange'],
    ['Q, Par-Mah', 'Book', 'Bok'],
    ['Coo', 'Bow (weapon)', 'Bue (våpen)'],
    ['Q, Hweh-Stah', 'Breeze', 'Bris'],
    ['Dees', 'Bride', 'Brud'],
    ['Q, Yahn-Tah', 'Bridge', 'Bro'],
    ['Hah-Thell', 'Broadsword', 'Storsverd'],
    ['Ah-Dahb', 'Building', 'Bygning'],
    ['Q, Will-Wahr-In', 'Butterfly', 'Sommerfugl'],
    ['Thahv-Ronn', 'Carpenter', 'Snekker'],
    ['Q, Tee-Oo-Roo', 'Cheese', 'Ost'],
    ['Heen-Ee', 'Children', 'Barn'],
    ['Ohst', 'City', 'By'],
    ['Rihst', 'Cleaver', 'Vedklyver'],
    ['Fown', 'Cloud', 'Sky'],
    ['Grohnd', 'Club', 'Klubbe'],
    ['Fah-Lass', 'Coast', 'Kyst'],
    ['Q, Kah-No', 'Commander', 'Kommandør'],
    ['Heem', 'Cool', 'Kjølig'],
    ['Rine', 'Crooked', 'Skjev'],
    ['Krah-Bahn', 'Crow', 'Kråke'],
    ['See-Geel', 'Dagger', 'Kniv'],
    ['Dur', 'Dark', 'Mørk'],
    ['Foo-In', 'Darkness', 'Mørke'],
    ['Ore', 'Day', 'Dag'],
    ['Gerth', 'Death', 'Død'],
    ['Toom-Bah', 'Deep valley', 'Dyp dal'],
    ['Ah-Rahs', 'Deer', 'Hjort'],
    ['Hoo', 'Dog', 'Dog'],
    ['Rohnd', 'Dome', 'Dom '],
    ['Ah-Marth', 'Doom', 'Undergang'],
    ['Coo-Goo', 'Dove', 'Due'],
    ['Dahd', 'Down', 'Ned'],
    ['Loog', 'Dragon', 'Drage'],
    ['Lore', 'Dream', 'Drømme'],
    ['Q, Loh-May', 'Dusk', 'Skumring'],
    ['Ahst', 'Dust', 'Støv'],
    ['Noh-Goth', 'Dwarf', 'Dverg'],
    ['Bahr', 'Dwelling', 'Bolig'],
    ['Thore-On', 'Eagle', 'Ørn'],
    ['Law', 'Ear', 'Øre'],
    ['Q, Keh-Men', 'Earth', 'Jorden'],
    ['Rune', 'East', 'Øst'],
    ['Toll-Oth', 'Eight', 'Åtte'],
    ['Ahn-Nah-Bohn', 'Elephant', 'Elefant'],
    ['Ethel', 'Elf', 'Elf'],
    ['Lohst', 'Empty', 'Tom'],
    ['Goth', 'Enemy', 'Fiende'],
    ['Ah-Dwee-Ahl', 'Evening', 'Kveld'],
    ['Hehn', 'Eye', 'Eye'],
    ['Vahn', 'Fair', 'Rettferdig'],
    ['Toog', 'Fat', 'Fett'],
    ['Q, Ahm-Bar', 'Fate', 'Skjebne'],
    ['Ah-Dah', 'Father', 'Far'],
    ['Mare-Eth', 'Feast', 'Fest'],
    ['Q, Queh-Say', 'Feather', 'Fjær'],
    ['Leh-Bed', 'Finger', 'Finger'],
    ['Nahr', 'Fire', 'Brann'],
    ['Leem', 'Fish', 'Fisk'],
    ['Powr', 'Fist', 'Knyttneve'],
    ['Leh-Ben', 'Five', 'Fem'],
    ['Lahkh', 'Flame', 'Flamme'],
    ['Tall-Ahn', 'Flat', 'Flate'],
    ['Pah-Nahs', 'Floor', 'Gulv'],
    ['Rohs', 'Foam', 'Skum'],
    ['Tahl', 'Foot', 'Fot'],
    ['Tow-Ray', 'Forest', 'Skog'],
    ['Kah-Nahd', 'Four', 'Fire'],
    ['Mehl-On', 'Friend', 'Venn'],
    ['Kah-Bore', 'Frog', 'Frosk'],
    ['Q, Ree-Ell', 'Maiden', 'Jomfru'],
    ['Ahn-Non', 'Gate', 'Port'],
    ['Q, Ah-Nah', 'Gift', 'Gave'],
    ['Rill', 'Gleam', 'Glimt'],
    ['Ah-Glar', 'Glorious', 'Strålende'],
    ['Glore', 'Gold', 'Gull'],
    ['Q, Lou-Ree-Nah', 'Golden', 'Gylden'],
    ['Q, Mahn', 'Good', 'God'],
    ['Q, Air-Oo-Lees-Ay', 'Grace', 'Nåde'],
    ['Sahlkh', 'Grass', 'Gress'],
    ['Breeth', 'Gravel', 'Grus'],
    ['Gah-Len', 'Orange', 'Orange'],
    ['Meeth', 'Grey', 'Grå'],
    ['Q, Loh-Ah', 'Growth', 'Vekst'],
    ['Finn', 'Hair', 'Hår'],
    ['Pair', 'Half', 'Halv'],
    ['Pair-Ee-An', 'Halfling', 'Halfling'],
    ['Thahm', 'Hall', 'Hall'],
    ['Kahm', 'Hand', 'Hånd'],
    ['Gahn-Nehl', 'Harp', 'Harpe'],
    ['Lohnd', 'Haven', 'Havn'],
    ['Dole', 'Head', 'Hode'],
    ['Q, Oh-Ray', 'Heart', 'Hjerte'],
    ['Q, Oor', 'Heat', 'Hete'],
    ['Meh-Nehl', 'Heaven', 'Himmel'],
    ['Thole', 'Helmet', 'Hjelm'],
    ['Ess-Gahl', 'Hiding', 'Gjemmested'],
    ['Ah-Mohn', 'Hill', 'Bakke'],
    ['Q, Un-Quay', 'Hollow', 'Hul'],
    ['Q, Eye-Nah', 'Holy', 'Hellig'],
    ['Ah-Mar', 'Home', 'Hjem'],
    ['Glee', 'Honey', 'Honning'],
    ['Q, Ahm-Pah', 'Hook', 'Krok'],
    ['Ehst-Ell', 'Hope', 'Å håpe'],
    ['Hohth', 'Horde', 'Horde'],
    ['Rahs', 'Horn', 'Horn'],
    ['Dell', 'Horror', 'Horror'],
    ['Rokh', 'Horse', 'Hest'],
    ['Rokh-Ear', 'Horse-lord', 'Hesteherre'],
    ['Far-Oth', 'Hunt', 'Å jakte'],
    ['Hair-Vehn', 'Husband', 'Mann'],
    ['Eem', 'I', 'Jeg'],
    ['Hell-Ehg', 'Ice', 'Is'],
    ['Eye', 'If', 'Om'],
    ['Ang-Ah', 'Iron', 'Jern'],
    ['Toll', 'Isle', 'Øy'],
    ['Q, Ahn-Kah', 'Jaws', 'Kjever'],
    ['Meer', 'Jewel', 'Juvel'],
    ['Lehn', 'Journey', 'Reise'],
    ['Q, Nah-Moe', 'Judge', 'Dommer'],
    ['Paykh', 'Juice', 'Juice'],
    ['Ah-Rahn', 'King', 'Konge'],
    ['Hehl-Eh-Dear', 'Kingfisher', 'Kongefisker'],
    ['Rokh-Behn', 'Knight', 'Ridder'],
    ['Here-Il', 'Lady', 'Lady'],
    ['Tour-Ekh', 'Lair', 'Bytte'],
    ['Eye-Lin', 'Lake', 'Sjø'],
    ['Q, Kahl-Mah', 'Lamp', 'Lampe'],
    ['Door', 'Land', 'Land'],
    ['Lah-Lithe', 'Laughter', 'Latter'],
    ['Lahs', 'Leaf', 'Løv'],
    ['Kah-Bed', 'Leap', 'Sprang'],
    ['Hayr', 'Left', 'Venstre'],
    ['Q, Tehng-Wah', 'Letter [of alphabet]', 'Brev [av alfabetet]'],
    ['Gah-Lahd', 'Light', 'Lett'],
    ['Rou', 'Lion', 'Løve'],
    ['Air-Ehb', 'Lonely', 'Ensom'],
    ['Here', 'Lord', 'Herre'],
    ['Q, Vahn-Wah', 'Lost', 'Mistet'],
    ['Mehl', 'Love', 'Å elske'],
    ['Gool', 'Magic', 'Magi'],
    ['Wehn', 'Maiden', 'Jomfru'],
    ['Ah-Dahn', 'Man', 'Mann'],
    ['Toor', 'Master', 'Mester'],
    ['Q, Nigh', 'Maybe', 'Kanskje'],
    ['Kohn-Dear', 'Mayor', 'Ordfører'],
    ['Neen', 'Me', 'Meg'],
    ['Tink', 'Metal', 'Metall'],
    ['Eh-Nehd', 'Middle', 'Midten'],
    ['Bell-Egg', 'Mighty', 'Mektig'],
    ['Heeth', 'Misty', 'Tåkete'],
    ['Q, Ah-Stah', 'Month', 'Måned'],
    ['Ith-Ill', 'Moon', 'Måned'],
    ['Q, Seel-May', 'Moonlight', 'Måneskinn'],
    ['Fear', 'Mortal', 'Dødelig'],
    ['Nah-Nehth', 'Mother', 'Mor'],
    ['Or-Ohd', 'Mountain', 'Mountain'],
    ['Ore-Odd-Behn', 'Mountaineer', 'Fjellklatrer'],
    ['Q, Ahn-Toe', 'Mouth', 'Munn'],
    ['Neen', 'My', 'Min'],
    ['Q, Ess-Ay', 'Name', 'Navn'],
    ['Peh-Thronn', 'Narrator', 'Forteller'],
    ['Yithe', 'Neck', 'Hals'],
    ['Sayn', 'New', 'Ny'],
    ['Doo-Leen', 'Nightingale', 'Nattergal'],
    ['Neh-Dare', 'Nine', 'Ni'],
    ['Fore-Odd', 'North', 'Nord'],
    ['Nehm', 'Nose', 'Nese'],
    ['Dore-On', 'Oak', 'Eik'],
    ['Ehn', 'Of', 'Av'],
    ['Meen', 'One', 'En'],
    ['Keer-Ith', 'Pass', 'Pass'],
    ['Gwithe', 'People', 'Mennesker'],
    ['Thohn', 'Pine tree', 'Furu'],
    ['Kehn-Nahn', 'Potter', 'Pottermaker'],
    ['Air-Nill', 'Prince', 'Prins '],
    ['Are-Uh-Nell', 'Princess', 'Prinsesse'],
    ['Bahnd', 'Prison', 'Fengsel'],
    ['Q, Mahn', 'Pure', 'Ren'],
    ['Bare-Ehth', 'Queen', 'Dronning'],
    ['Q, All-Ah-Tah', 'Radiance', 'Stråling'],
    ['Q, Ah-Hah', 'Rage', 'Raseri'],
    ['Rohss', 'Rain', 'Regn'],
    ['Neen-Ee-Akh', 'Rainbow', 'Regnbue'],
    ['Nar', 'Rat', 'Rotte'],
    ['Q, Are-Dah', 'Realm', 'Rike'],
    ['Kah-Rahn', 'Red', 'Rød'],
    ['Roo-In', 'Red flame', 'Rød flamme'],
    ['Gwehth', 'Regiment', 'Regiment'],
    ['Q, Ess-Tay', 'Rest', 'Rest'],
    ['Q, Noo-Quern-Ah', 'Reversed', 'Reversert'],
    ['Fayr', 'Right', 'Rett'],
    ['Q, Core', 'Ring', 'Ring'],
    ['Do-In', 'River', 'Elv'],
    ['Q, Core', 'Round', 'Rund'],
    ['Are', 'Royal', 'Kongelig'],
    ['Ahs-Kah', 'Rushing', 'Hast'],
    ['Leeth', 'Sand', 'Sand'],
    ['Eye-Are', 'Sea', 'Sjø'],
    ['Q, Tay-Mah', 'Series', 'Serien'],
    ['Oh-Dohg', 'Seven', 'Sju'],
    ['Die', 'Shadow', 'Skygge'],
    ['Mige [Hard G]', 'Sharp', 'Skarp'],
    ['Keer', 'Ship', 'Skip'],
    ['Q, Teh-Tah', 'Sign', 'Tegn'],
    ['Deen', 'Silent', 'Stille'],
    ['Kehl-Ehb', 'Silver', 'Sølv'],
    ['Ehn-Egg', 'Six', 'Seks'],
    ['Nihn', 'Slender', 'Tynn'],
    ['Lig', 'Snake', 'Slange'],
    ['Gloss', 'Snow', 'Snø'],
    ['Leen-Odd', 'Song', 'Sang'],
    ['Gool', 'Sorcery', 'Trolldom'],
    ['Hah-Rahd', 'South', 'Sør'],
    ['Ehkh', 'Spear', 'Spyd'],
    ['Nithe', 'Spearpoint', 'Spydspiss'],
    ['Un-Goal', 'Spider', 'Edderkopp'],
    ['Q, Fay-Ah', 'Spirit', 'Ånd'],
    ['Wihng', 'Spray', 'Spray'],
    ['Ell-En', 'Star', 'Stjerne'],
    ['Gohnd', 'Stone', 'Sten'],
    ['Tire', 'Straight', 'Rett'],
    ['Sear', 'Stream', 'Bekk'],
    ['Rahth', 'Street', 'Gate'],
    ['Thahl-Ee-On', 'Strong', 'Sterk'],
    ['Brah-Goal', 'Sudden', 'Plutselig'],
    ['Ah-Nore', 'Sun', 'Sol'],
    ['Q, Ah-Ray', 'Sunlight', 'Sollys'],
    ['Too-Ee-Linn', 'Swallow [bird]', 'Svale [fugl]'],
    ['Q, Ahl-Quah', 'Swan', 'Svane'],
    ['Meh-Gill', 'Sword', 'Sverd'],
    ['Narn', 'Tale', 'Tale'],
    ['Hall', 'Tall', 'Høy'],
    ['Kire', 'Ten', 'Ti'],
    ['Line', 'Thread', 'Tråd'],
    ['Neh-Lehd', 'Three', 'Tre'],
    ['Fehn', 'Threshold', 'Terskel'],
    ['Lahnk', 'Throat', 'Hals'],
    ['Try', 'Through', 'Gjennom'],
    ['Q, Lahm-Bay', 'Tongue', 'Tunge'],
    ['Neh-Leg', 'Tooth', 'Tann'],
    ['Q, Nwahl-May', 'To torment', 'Å lide'],
    ['Q, Eh-Nah', 'Toward', 'Mot'],
    ['Mee-Nahs', 'Tower', 'Tårn'],
    ['Q, Har-Mah', 'Treasure', 'Skatt'],
    ['Q, All-Dah', 'Tree', 'Tre'],
    ['Tahd', 'Two', 'To'],
    ['New', 'Under', 'Under'],
    ['Q, Ah-Var-Ee', 'Unwilling', 'Uvillig'],
    ['Q, Ah-Stahl-Doh', 'Valiant', 'Tapper'],
    ['Im-Lahd', 'Valley', 'Dal'],
    ['Rahm', 'Wall', 'Vegg'],
    ['Rah-Nah', 'Wanderer', 'Vandrer'],
    ['Oh-Tar', 'Warrior', 'Kriger'],
    ['Nehn', 'Water', 'Vann'],
    ['Q, Ehn-Quee-Ay', 'Week', 'Uke'],
    ['My', 'Well', 'Vel'],
    ['Ay-Thell', 'Wells', 'Brønner'],
    ['Gowr', 'Werewolf', 'Varulv'],
    ['Dune', 'West', 'Vest'],
    ['Mehsk', 'Wet', 'Våt'],
    ['Nimm', 'White', 'Hvit'],
    ['Hair-Vehss', 'Wife', 'Kone'],
    ['Tah-Thar', 'Willow', 'Piletre'],
    ['Sool', 'Wind', 'Vind'],
    ['Hehn-Ehth', 'Window', 'Vindu'],
    ['Behn', 'Without', 'Uten'],
    ['Drowg', 'Wolf', 'Ulv'],
    ['Tah-Vore', 'Woodpecker', 'Hakkespett'],
    ['Ah-Mar', 'World', 'Verden'],
    ['Yayn', 'Year', 'År'],
    ['Mah-Leen-Ah', 'Blue', 'Blå'],
    ['Lay', 'You', 'Du'],
    ['Nehth', 'Young', 'Ung'],
    ['Leen', 'Your', 'Din']
];
