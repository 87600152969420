export default [
    ['yoa', 'in exchange for', 'i bytte for'],
    ['tompawll', 'rain plant', 'regnplante'],
    ['tsre’i', 'throw', 'kaste'],
    ['nìzawnong', 'safely', 'trygt'],
    ['kinä', 'seven', 'sju'],
    ['tìmal', 'trustworthiness', 'troverdighet'],
    ['ku’up', 'heavy (physical weight)', 'tung (fysisk vekt)'],
    ['rìkean', 'green leaf', 'grønne blad'],
    ['lìng', 'float in the air, hover', 'sveve i luften, hover'],
    ['säfpìl', 'idea', 'ide'],
    ['tì’efu', 'feeling', 'følelse'],
    ['trrkive', 'Saturday', 'Lørdag'],
    ['tsang', 'a piercing', 'en piercing'],
    ['hena', 'carry', 'bære'],
    ['wok', 'loud', 'høyt'],
    ['hawng', 'overabundance', 'overflod'],
    ['tìtsan’ul', 'improvement', 'forbedring'],
    ['nìkmar', 'in the right season', 'i riktig sesong'],
    ['fìpo', 'this one (person or thing)', 'denne (person eller ting)'],
    ['tupe', 'who', 'som'],
    ['tìngong', 'laziness', 'latskap'],
    ['pamtseo si', 'play music (with fa for instrument played)', 'spille musikk (med fa som spilt instrument)'],
    ['tìkangkemvi', 'project, piece of work', 'prosjekt, stykke arbeid'],
    ['ta', 'from', 'fra'],
    ['peng', 'tell', 'fortelle'],
    ['lonu', 'release, let go', 'slipp, la gå'],
    ['yomtìng', 'feed (with dative object)', 'mate (med dativ objekt)'],
    ['txantompa', 'rainstorm, heavy rain', 'regnstorm, kraftig regn'],
    ['mip', 'new', 'ny'],
    ['syo', 'light (physical weight), lightweight', 'lys (fysisk vekt), lett'],
    ['nìnänän', 'decreasingly, less and less', 'mindre og mindre'],
    ['alunta', 'because', 'fordi'],
    ['fewi', 'chase', 'jage'],
    ['saa', 'threatening cry', 'å gråte truende '],
    ['‘opin', 'color', 'farge'],
    ['sä’anla', 'yearning', 'lengsel'],
    ['plltxe', 'speak', 'snakke'],
    ['fayluta', 'these words', 'disse ordene'],
    ['srekamtrr', 'the time before noon', 'tiden før middag'],
    ['nìran', 'basically, fundamentally, in essence', 'i utgangspunktet, fundamentalt, i hovedsak'],
    ['wempongu', 'squad, military clan, battle party', 'tropp, militær klan, kampgruppe'],
    ['tsìsyì', 'whisper', 'hviske'],
    ['toruk makto', 'toruk rider', 'toruk rytter'],
    ['pam', 'sound', 'lyd'],
    ['ewku', 'taste (not intentionally)', 'smak (ikke med vilje)'],
    ['‘okrol', 'history (ancient)', 'historie (gammel)'],
    ['ngawng', 'worm', 'orm'],
    ['nìyewla', 'in a disappointing fashion', 'på en skuffende måte'],
    ['wou', 'be amazing, fascinating (slang)', 'være fantastisk, fascinerende (slang)'],
    ['prrku', 'womb', 'livmor'],
    ['ngä’än', 'be miserable', 'være ulykkelig'],
    ['‘ekxin', 'tight', 'trang'],
    ['letsim', 'original', 'original'],
    ['ftxavang', 'passionate', 'lidenskapelig'],
    ['srungsiyu', 'assistant, helper', 'assistent, hjelper'],
    ['kangay si', 'validate, confirm', 'validere, bekrefte'],
    ['hapxìtu', 'member', 'medlem'],
    ['kesran', 'mediocre', 'middelmådig'],
    ['ftumfa', 'out of, from inside', 'ut av, fra innsiden'],
    ['am’aluke', 'without a doubt', 'uten tvil'],
    ['way a plltxe', 'spoken poem (as a melodic recital)', 'snakkes dikt (som en melodisk recital)'],
    ['lefpomtokx', 'healthy', 'sunt'],
    ['hamalo', 'last time', 'sist'],
    ['tìso’ha', 'enthusiasm', 'entusiasme'],
    ['set', 'now', 'nå'],
    ['ultxatu', 'meeting participant', 'møtedeltaker'],
    ['wotx', 'totality', 'totaliteten'],
    ['nì’o’', 'enjoyable', 'fornøyelig'],
    ['taw', 'sky', 'himmel'],
    ['swoa', 'intoxicating beverage', 'berusende drikke'],
    ['tìtxen', 'awakeness, the state of waking', 'awakeness'],
    ['säplltxevi', 'comment', 'kommentar'],
    ['seysonìltsan', 'well done!', 'bra jobba!'],
    ['txanfwerwì', 'blizzard', 'snøstorm'],
    ['wip', 'salty', 'salt'],
    ['tsngal', 'cup', 'kopp'],
    ['nìno', 'in detail', 'i detalj'],
    ['tsko swizaw', 'bow and arrow', 'pil og bue'],
    ['hertxayo', 'snow field', 'snø felt'],
    ['pay', 'water, liquid', 'vann, væske'],
    ['eyktanay', 'deputy general, one step down from leader', 'assisterende daglig leder'],
    ['nìhona', 'sweetly', 'søtt'],
    ['zize’', 'hellfire wasp', 'helvetesildveps'],
    ['txintìn', 'occupation', 'okkupasjon'],
    ['aylaru', 'to the others', 'til andre'],
    ['rikx', 'move', 'flytte'],
    ['ftxey', 'or', 'eller'],
    ['tìsnaytx', 'loss', 'tap'],
    ['reltseo', 'visual art', 'visuell kunst'],
    ['tsyal', 'wing', 'fløy'],
    ['tìkakpam', 'deafness', 'døvhet'],
    ['kxeyey', 'mistake', 'feil'],
    ['‘llngo', 'hip', 'hip'],
    ['stä’nì', 'catch', 'fangst'],
    ['za’ärìp', 'pull', 'trekke'],
    ['fwew', 'look for', 'se etter'],
    ['latem', 'change', 'endring'],
    ['tìsom', 'heat', 'hete'],
    ['lì’uvan', 'wordplay', 'ordspill'],
    ['yrrap', 'storm', 'storm'],
    ['snatanhìtsyìp', 'star cluster', 'stjernesamling'],
    ['hultstxem', 'hinder', 'hindre'],
    ['txantslusam', 'wise', 'klok'],
    ['kllfro’', 'be responsible', 'være ansvarlig'],
    ['fu', 'or', 'eller'],
    ['tìrawn', 'replacement', 'erstatning'],
    ['seze', 'blue flower', 'blå blomst'],
    ['nìftue', 'easily', 'lett'],
    ['txal', 'back (of the body)', 'baksiden (av kroppen)'],
    ['reltseotu', 'artist', 'artist'],
    ['‘awve', 'first', 'først'],
    ['sneyä', 'his own, her own, its own', 'hans egen, hennes egen, sin egen'],
    ['nì’aw', 'only', 'bare'],
    ['pefya', 'how', 'hvordan'],
    ['tstal', 'knife', 'kniv'],
    ['oeng', 'we two', 'oss to'],
    ['lesnonrra', 'full of selfpride', 'full av stolthet'],
    ['eampin', 'blue, green', 'blå, grønn'],
    ['tìtxen si', 'to wake up', 'å våkne opp'],
    ['täftxuyu', 'weaver', 'en vever'],
    ['yur', 'wash', 'vask'],
    ['tatep', 'lose track', 'miste oversikten '],
    ['hek', 'be curious', 'være nysgjerrig'],
    ['trr’ong', 'dawn, sunrise', 'daggry, soloppgang'],
    ['‘ali’ä', 'collar', 'krage'],
    ['nawm', 'great, noble', 'stor, edel'],
    ['‘awlo', 'once', 'gang'],
    ['pamtseo', 'music', 'musikk'],
    ['lawr', 'melody', 'melodi'],
    ['ontsang', 'nose ring', 'nesering'],
    ['koum', 'rounded, curved', 'avrundet, buet'],
    ['awaiei', 'paradise', 'paradis'],
    ['tìngäzìk', 'problem', 'problem'],
    ['wawe', 'importance', 'viktighet'],
    ['‘eveng', 'child', 'barn'],
    ['lok', 'close to', 'nærme'],
    ['tsawl', 'big (in size), tall', 'stor (i størrelse), høy'],
    ['kawtseng', 'nowhere', 'ingensteds'],
    ['mìn', 'turn, rotate on an axis', 'snu, rotere på en akse'],
    ['‘ìn', 'be busy, be occupied', 'være opptatt'],
    ['stxenu', 'offer', 'tilbud'],
    ['alaksi', 'ready', 'klar'],
    ['tìoeyktìng', 'explanation', 'forklaring'],
    ['tstxolì’u', 'noun', 'substantiv'],
    ['lefkrr', 'current', 'strøm'],
    ['sla’tsu', 'describe', 'beskrive'],
    ['sru’', 'crush, trample', 'knuse, tråkke'],
    ['kìte’e', 'service', 'tjeneste'],
    ['sästarsìm', 'collection', 'samling'],
    ['yora’', 'win', 'seier'],
    ['wä', 'against (as in: fight against)', 'mot (som i: kampen mot)'],
    ['kxanì', 'forbidden', 'forbudt'],
    ['tìk’ìn', 'leisure', 'fritid'],
    ['kxll', 'charge, to attack', 'angripe'],
    ['oìsss si', 'hiss', 'susing'],
    ['tsìng', 'four', 'fire'],
    ['keftxo', 'unhappy', 'ulykkelig'],
    ['maweypeyyu', 'one who is patient', 'en som er pasient'],
    ['olo’eyktan', 'clan leader', 'klanleder'],
    ['munsna', 'pair', 'par'],
    ['ngampam si', 'rhyme', 'rim'],
    ['fnepe', 'which kind', 'hvilken type'],
    ['flew', 'throat', 'hals'],
    ['masat', 'breastplate (armor)', 'brynje (rustning)'],
    ['sìn', 'on, onto', 'på  '],
    ['pxawpa', 'border', 'grensen'],
    ['tireaioang', 'spirit animal', 'åndedyr'],
    ['srä', 'cloth', 'tøystykke'],
    ['tì’awm si', 'camp', 'camp'],
    ['äo', 'under, below', 'under'],
    ['pähem', 'arrive', 'ankomme'],
    ['kxamlä', 'through (via the middle of)', 'videre (via midten av)'],
    ['inanfya', 'sense (means of perception)', 'fornuft '],
    ['nìftxan', 'so ', 'så'],
    ['ätxäle si', 'request ', 'forespørsel'],
    ['kulat', 'reveal, bring forth', 'avsløre, bringe frem'],
    ['fratxon', 'every night', 'hver natt'],
    ['me’em', 'harmonious', 'harmonisk'],
    ['rey', 'live', 'leve'],
    ['toruk', 'last shadow', 'siste skygge'],
    ['ma', 'O (vocative marker)', 'O (vocative markør)'],
    ['lrrtok', 'smile', 'smil'],
    ['kxa', 'mouth', 'munn'],
    ['ketsran', 'no matter, no matter what, whatever', 'uansett, uansett hva, uansett'],
    ['tsong', 'valley', 'dalen'],
    ['kaym', 'evening', 'kveld'],
    ['tutan', 'male (person)', 'mannlig (person)'],
    ['tuté', 'female (person)', 'kvinnelig (person)'],
    ['nì’i’a', 'finally, at long last', 'endelig'],
    ['terkup', 'die', 'dø'],
    ['trrtsìve', 'Wednesday', 'onsdag'],
    ['pamtseotu', 'musician', 'musiker'],
    ['hiyìk', 'funny, strange', 'morsom, rar'],
    ['fwefwi', 'whistle', 'fløyte'],
    ['syay', 'fate', 'skjebne'],
    ['txumpaywll', 'poison water plant', 'giftig vannplante'],
    ['kaltxì', 'hello', 'hallo'],
    ['nìhek', 'strangely, oddly', 'merkelig'],
    ['fe’', 'bad', 'dårlig'],
    ['tswintsyìp', 'little queue', 'liten kø'],
    ['pìwopx', 'cloud', 'sky'],
    ['apxa', 'large', 'stor'],
    ['tìkanu', 'intelligence', 'intelligens'],
    ['tìtxula', 'construction, constructed thing', 'konstruksjon'],
    ['zong', 'save, defend', 'lagre, forsvare'],
    ['‘änsyem', 'complete', 'komplett'],
    ['tìno', 'thoroughness, attention to detail', 'grundighet, oppmerksomhet for detaljer'],
    ['hangham', 'laugh', 'latter'],
    ['reyfya', 'way of living, culture', 'levemåte, kultur'],
    ['tìomum', 'knowledge', 'kunnskap'],
    ['anìheyu', 'blue spiral plant', 'blå spiralplante'],
    ['rì’ìr si', 'reflect, imitate', 'reflektere, imitere'],
    ['sngä’itseng', 'beginning, starting position, initial location', 'begynnelse, utgangsstilling'],
    ['nìtsìsyì', 'by whispering, in a whisper', 'ved å hviske '],
    ['ska’a', 'destroy', 'ødelegge'],
    ['vol', 'eight', 'åtte'],
    ['law si', 'make clear', 'gjøre det klart'],
    ['tìsyor', 'relaxation', 'avslapping'],
    ['zäfi', 'modified', 'modifisert'],
    ['txi', 'hurry', 'skynde'],
    ['pe’un', 'decide', 'bestemme'],
    ['ngim', 'long (physical length)', 'lang (fysisk lengde)'],
    ['näk', 'drink', 'drikke'],
    ['snew', 'constrict, tighten', 'begrense, stramme'],
    ['fìtxan', 'so, to such an extent', 'så, i en slik grad'],
    ['vingkap', 'occur to one, pop into one’s mind', 'skje til en, dukke opp i ens sinn'],
    ['tslolam', 'get it, I understand', 'skjønner, jeg forstår'],
    ['tìyawr', 'correctness', 'korrekthet'],
    ['nekx', 'burn, consume', 'brenne, forbruke'],
    ['nìtxay', 'horizontally', 'horisontalt'],
    ['säzärìp', 'face lead, rein', 'tøyler'],
    ['fngapsutxwll', 'metalfollowing plant', 'metalloppmerksom plante'],
    ['fmal', 'sustain', 'opprettholde'],
    ['nìprrte’', 'gladly', 'gjerne'],
    ['ketsuktswa’', 'unforgettable', 'uforglemmelig'],
    ['snonrra', 'selfpride (negative connotation)', 'negativ stolthet'],
    ['numultxatu', 'classmate, member of a class', 'klassekamerat'],
    ['‘engeng', 'level', 'nivå'],
    ['srìn', 'transfer from one to another ', 'overføre fra den ene til en annen'],
    ['txew', 'limit', 'grense'],
    ['txantxewvay', 'maximally', 'maksimalt'],
    ['puk', 'book', 'bok'],
    ['ultxa si', 'meet with someone intentionally', 'møte noen med vilje'],
    ['vewng', 'look after', 'se etter'],
    ['loreyu', 'beautiful spiral', 'vakker spiral'],
    ['muntxatan', 'husband', 'mann'],
    ['keltsun', 'impossible', 'umulig'],
    ['wrrpa', 'outside', 'utenfor'],
    ['na', 'like, as', 'lignende, så'],
    ['maw', 'after (time)', 'etter (tid)'],
    ['nìflrr', 'gently', 'forsiktig'],
    ['mek', 'empty', 'tom'],
    ['mowar', 'piece of advice', 'et lite råd'],
    ['fkxake', 'itch', 'kløe'],
    ['skxom', 'chance, opportunity', 'sjanse, mulighet'],
    ['nìwok', 'loudly', 'høylytt'],
    ['pehem', 'what (action)', 'hva (handling)'],
    ['laro', 'clean', 'ren'],
    ['ftxulì’u', 'give speech', 'holde tale'],
    ['tsawl slu', 'grow', 'vokse'],
    ['mong', 'to trust', 'til å stole på'],
    ['txe’lan', 'heart', 'hjerte'],
    ['tsopì', 'lung', 'lunge'],
    ['spä', 'jump', 'hoppe'],
    ['taron', 'hunt', 'jakt'],
    ['kato', 'rhythm', 'rytme'],
    ['furia', 'that', 'det'],
    ['koakte', 'old woman', 'gammel dame'],
    ['rengop', 'design', 'utforming'],
    ['ulte', 'and (clausal conjunction)', 'og (clausal sammen)'],
    ['kavuk', 'treachery', 'forræderi'],
    ['tìtsyär', 'rejection', 'avvisning'],
    ['puve', 'sixth', 'sjette'],
    ['var', 'to continue', 'å fortsette'],
    ['vezo', 'be in order, be organized', 'være i orden'],
    ['lätxayn', 'defeat in battle', 'nederlag i kamp'],
    ['pa’li', 'horse', 'hest'],
    ['vawm', 'dark', 'mørk'],
    ['pxor', 'explode', 'eksplodere'],
    ['stxong', 'strange ', 'merkelig'],
    ['mal', 'trustworthy', 'troverdig'],
    ['nìswey', 'optimally, best', 'optimalt, best'],
    ['tstew', 'brave', 'modig'],
    ['sämyam', 'hug, embrace', 'klem, omfavne'],
    ['tì’eyng', 'answer, response', 'svar '],
    ['sok', 'recent', 'siste'],
    ['‘eko', 'attack', 'angrep'],
    ['hawntsyokx', 'glove', 'hanske'],
    ['tìketeng', 'difference', 'forskjell'],
    ['tsatseng', 'there', 'der '],
    ['fkew', 'mighty', 'mektig'],
    ['nìnrra', 'proudly, with pride', 'stolt, med stolthet'],
    ['srer', 'appear', 'vise'],
    ['yo', 'surface', 'overflate'],
    ['eykyu', 'leader', 'leder'],
    ['slantire', 'inspiration', 'inspirasjon'],
    ['nìkemweypey', 'impatiently', 'utålmodig'],
    ['rìn', 'wood', 'trevirke'],
    ['nìngay', 'truly', 'virkelig'],
    ['fkio', 'tetrapteron', 'tetrapteron'],
    ['tswa’', 'forget', 'glemme'],
    ['lehawng', 'excessive', 'dreven'],
    ['hangvur', 'joke, funny story', 'vits, morsom historie'],
    ['eltu', 'brain', 'hjerne'],
    ['sang', 'warm', 'varm'],
    ['vawmpin', 'dark color', 'mørk farge'],
    ['väng', 'thirsty', 'tørst'],
    ['‘ì’awn', 'remain, stay', 'forbli, oppholde seg'],
    ['syura', 'energy (physical or spiritual)', 'energi (fysisk eller åndelig)'],
    ['newomum', 'be curious, want to know', 'være nysgjerrig, ønske å vite'],
    ['win säpi', 'hurry', 'skynde seg'],
    ['muiä', 'fair, justified', 'rettferdig'],
    ['snayì', 'stairs', 'trapper'],
    ['sutx', 'track, follow', 'spore, følge'],
    ['eltu si', 'pay attention, quit goofing off', 'følg med, slutt å tulle'],
    ['steyki', 'anger, make someone angry', 'sinne, gjøre noen sint'],
    ['hrrap', 'danger', 'fare'],
    ['moe', 'we two, us two', 'vi to, oss to'],
    ['zamunge', 'bring', 'bringe'],
    ['säpom', 'kiss', 'kyss'],
    ['‘etnaw', 'shoulder', 'skulder'],
    ['pxeyve', 'third (ordinal)', 'tredje'],
    ['fnu', 'quiet, be quiet', 'stille, være stille'],
    ['Jakesully', 'moron', 'idiot'],
    ['toktor', 'doctor', 'lege'],
    ['lapo', 'other one person or thing', 'andre en person eller ting'],
    ['rusey', 'alive', 'live'],
    ['ngong', 'lazy', 'lat'],
    ['yafkeyk', 'weather', 'vær'],
    ['ngop', 'create', 'skape'],
    ['tengkrr', 'while, same time as', 'mens, samtidig som'],
    ['kxamtxomaw', 'after midnight', 'etter midnatt'],
    ['tìsraw', 'pain', 'smerte'],
    ['kxeltek', 'pick up, lift', 'plukke opp, løft'],
    ['nìpxi', 'especially, pointedly, unambiguously', 'spesielt, demonstrativt, entydig'],
    ['tsawa', 'that', 'det'],
    ['sì', 'and  ', 'og  '],
    ['hasey', 'done, finished', 'gjort, ferdig'],
    ['tìväng', 'thirst', 'tørst'],
    ['ngian', 'however', 'men'],
    ['mìftxele', 'in this regard, related to this matter', 'i denne forbindelse'],
    ['poe', 'she', 'hun'],
    ['kawng', 'bad, evil', 'dårlig, ond'],
    ['tuvon', 'lean', 'lean'],
    ['nari', 'eye', 'øye'],
    ['fwäkìwll', 'orchid', 'orkide'],
    ['lì’fya', 'language', 'språk'],
    ['lemweypey', 'patient', 'pasient'],
    ['kin', 'need', 'trenge'],
    ['säwäsultsyìp', 'a contest', 'en konkurranse'],
    ['nìngong', 'lazily', 'dovent'],
    ['txantsan', 'excellent', 'utmerket'],
    ['sänumvi', 'lesson', 'leksjon'],
    ['nìlam', 'apparently', 'tilsynelatende'],
    ['‘ìheyu', 'spiral', 'spiral'],
    ['sleyku', 'produce', 'produsere'],
    ['wayä aylì’u', 'words of a poem or lyrics of a song', 'ord av et dikt eller sangtekster'],
    ['fpìlfya', 'thought pattern, way of thinking', 'tankemønster, tenkemåte'],
    ['nìwan', 'secretly', 'hemmelighet'],
    ['zìsìtay', 'next year', 'neste år'],
    ['tsmìm', 'track (of animal or person)', 'spor (av dyr eller person)'],
    ['mrrtrr', 'five day work week', 'fem dagers arbeidsuke'],
    ['nìwin', 'fast, quickly', 'rask, raskt'],
    ['pxiut', 'razor palm, sharp tree', 'barberhøvelpalme, skarpt tre'],
    ['layompin', 'black', 'svart'],
    ['tìpe’un', 'decision', 'beslutning'],
    ['ha’ngir', 'afternoon', 'ettermiddag'],
    ['nìli', 'in advance', 'på forhånd'],
    ['re’o', 'head', 'hode'],
    ['meyp', 'weak', 'svak'],
    ['tìkxey', 'incorrectness, mistakeness', 'uriktigheter'],
    ['nìn', 'look at', 'se på'],
    ['fuke', 'or not', 'eller ikke'],
    ['tìtslam', 'understanding, intelligence', 'forståelse, intelligens'],
    ['mll’an', 'accept', 'akseptere'],
    ['hahaw', 'sleep', 'søvn'],
    ['tsengpe', 'where', 'hvor'],
    ['krr', 'time', 'tid'],
    ['nìronsrel', 'in imagination, by imagination', 'i fantasien, med fantasi'],
    ['fyeyn', 'ripe, mature, adult', 'moden, voksen'],
    ['zìmtswo', '(sense of) touch', '(følelse av) berørings'],
    ['lie', 'experience', 'erfaring'],
    ['tìwäte', 'dispute, argument', 'uoverenstemmelse, krangel'],
    ['lekye’ung', 'insane, crazy', 'sinnsyk, gal'],
    ['tomperwì', 'sleet', 'sludd'],
    ['hawre’', 'hat', 'hatt'],
    ['yapay', 'mist, fog, steam', 'tåke, damp'],
    ['tsmuk', 'sibling', 'søsken'],
    ['ioang', 'animal, beast', 'dyr, beist'],
    ['pxuntil', 'elbow', 'albue'],
    ['zel', 'then', 'deretter'],
    ['‘itan', 'son', 'sønn'],
    ['snep', 'narrow', 'smal'],
    ['ngatsyìp', 'little you, you little', 'lille deg'],
    ['tok', 'be at, occupy a space', 'være på, oppta en plass'],
    ['vospxìam', 'last month', 'forrige måned'],
    ['koaktan', 'old man', 'gammel mann'],
    ['‘okvur', 'history', 'historie'],
    ['pxaw', 'around', 'rundt'],
    ['ham', 'previous', 'forrige'],
    ['txawew', 'very cold', 'veldig kaldt'],
    ['uran', 'boat', 'båt'],
    ['pamrel', 'writing', 'skriving'],
    [
        'tsahey',
        'expression of warning or frustration, ‘crap!’, ‘ah, hell!’',
        "uttrykk for advarsel eller frustrasjon, 'dritt!', 'ah, faen!"
    ],
    ['tsyokx', 'hand', 'hånd'],
    ['sngap', 'sting', 'svie'],
    ['ikranay', 'forest banshee', 'skog'],
    ['utral', 'tree', 'treet'],
    ['‘on', 'shape, form', 'fasong, form'],
    ['txen', 'awake', 'våken'],
    ['nìfkrr', 'lately, currently', 'i det siste, for tiden'],
    ['syeprel', 'camera', 'kamera'],
    ['fmawn', 'news, something to report', 'nyheter, noe å rapportere'],
    ['trrmrrve', 'Thursday', 'Torsdag'],
    ['steftxaw', 'examine, check', 'undersøke, sjekke'],
    ['fìtsenge', 'here, this place', 'her, dette stedet'],
    ['pxim', 'erect, upright', 'oppreist'],
    ['txewnga’', 'having a limit, not without bounds', 'ha en grense, ikke uten grenser'],
    [
        'ley',
        'be of value, have some positive value, be worth something',
        'være av verdi, har noen positiv verdi, være verdt noe'
    ],
    ['zeswa', 'grass', 'gress'],
    ['awnga', 'we', 'vi'],
    ['swek', 'bar, rod, pole', 'stang'],
    ['nìtxi', 'hurriedly, in a frenzied way', 'hast'],
    ['fratseng', 'everywhere', 'overalt'],
    ['nìslele', 'by swimming', 'ved å svømme'],
    ['wew', 'cold', 'forkjølelse'],
    ['kehe', 'no', 'ikke'],
    ['ta’em', 'from above', 'ovenfra'],
    ['nìk’ärìp', 'steadily', 'jevnt'],
    ['hawl', 'prepare', 'forberede'],
    ['tspang', 'kill', 'drepe'],
    ['fkxen', 'vegetable (food)', 'grønnsak'],
    ['ve’kì', 'hate', 'hat'],
    ['nìrangal', 'wish (contrary to fact)', 'ønsker (i motsetning til fakta)'],
    ['kakpam', 'deaf', 'døv'],
    ['sosul', 'pleasant smell of nearby running water', 'behagelig lukt av nærmeste rennende vann'],
    ['tsukyom', 'edible', 'spiselig'],
    ['kawtu', 'noone', 'ingen'],
    ['le’en', 'speculative, intuitive', 'spekulativ, intuitiv'],
    ['kifkey', 'world (physical, solid)', 'verden (fysisk, solid)'],
    ['tsewtx', 'dirty', 'skitne'],
    ['ohakx', 'hungry', 'sulten'],
    ['ronsrel', 'something imagined', 'noe forestilt'],
    ['sängä’än', 'bout of suffering; episode of depression', 'anfall av lidelse; episode av depresjon'],
    ['srekrr', 'before, already, ahead of time', 'før, allerede, på forhånd'],
    ['nìsteng', 'similarly', 'tilsvar'],
    ['tsyìl', 'climb, scale (pulling entire body up)', 'klatre (trekke hele kroppen opp)'],
    ['trr’awve', 'Sunday', 'Søndag'],
    ['ronsem', 'mind', 'sinn'],
    ['tìpawm', 'question', 'spørre'],
    ['nìk’ong', 'slowly', 'sakte'],
    ['ral', 'meaning', 'som betyr'],
    ['tìhawnu si', 'protect', 'beskytte'],
    ['tsamsiyu', 'warrior', 'kriger'],
    ['ralpeng', 'interpret', 'tolke'],
    ['nìvingkap', 'by the way, incidentally', 'forresten, apropos'],
    ['meuianga’', 'honorable', 'hederlig'],
    ['piak si', 'open', 'åpen'],
    ['srungtsyìp', 'helpful hint, tip', 'nyttig tips, hint'],
    ['speng', 'restore', 'gjenopprette'],
    ['pup', 'short (physical length)', 'kort (fysisk lengde)'],
    ['äie', 'vision (spiritual)', 'visjon (åndelig)'],
    ['säsulìn', 'hobby, pleasureyielding activity', 'hobby, gledelig aktivitet'],
    ['tìve’kì', 'hatred', 'hat'],
    ['fyole', 'sublime, beyond perfection', 'enestående, utover perfeksjon'],
    ['‘aw', 'one', 'en'],
    ['letrr', 'daily', 'daglig'],
    ['trrpeve', 'which day', 'hvilken dag'],
    ['au', 'exclamation of consternation', 'utrop av forskrekkelse'],
    ['tsleng', 'FALSE', 'falsk'],
    ['kelkin', 'unnecessary', 'unødvendig'],
    ['lepwopx', 'cloudy', 'overskyet'],
    ['rawp', 'bladder polyp', 'blærrepolypp'],
    ['waytelem', 'song cord', 'sangnote'],
    ['zekwä', 'finger', 'finger'],
    ['tsawlhì’', 'size', 'størrelse'],
    ['sevin', 'pretty', 'pen'],
    ['tìflä', 'success (in general)', 'suksess (generelt)'],
    ['kxutu', 'enemy', 'fiende'],
    ['zeswavi', 'blade of grass', 'gresstrå'],
    ['vitra', 'soul', 'sjel'],
    ['nekll', 'down (direction)', 'ned (retning)'],
    ['lahe', 'other, else', 'andre, annet'],
    ['yawnetu', 'loved one', 'kjære'],
    ['hìm', 'small (in quantity)', 'små (i antall)'],
    ['lomtu', 'missed person', 'savnet person'],
    ['txonam', 'last night', 'i går kveld'],
    ['tìsop', 'journey', 'reise'],
    ['ylltxep', 'communal fire or fire pit', 'fellesbål eller branngrop'],
    ['fpi', 'for the sake of, for the benefit of', 'for moro skyld, til fordel for'],
    ['tem', 'shoot', 'skyte'],
    ['emrey', 'survive', 'overleve'],
    ['frrfen', 'visit', 'besøk'],
    ['hìmtxampe', 'how much', 'hvor mye'],
    ['‘i’a', 'to end', 'å ende'],
    ['holpxay', 'number', 'nummer'],
    ['nìkeftxo', 'unfortunately, sadly', 'dessverre'],
    ['lefpom', 'happy', 'lykkelig'],
    ['nìhol', 'few, not many', 'få, ikke mange'],
    ['rawke', 'alarm cry, call to defense', 'alarmrope, rope til forsvar'],
    ['ekxtxu', 'rough', 'grov'],
    ['netrìp', 'luckily, happily', 'heldigvis, lykkelig'],
    ['slele', 'swim', 'svømmetur'],
    ['sloan', 'pour', 'helle'],
    ['tsyo', 'flour', 'mel'],
    ['teng', 'same, equal', 'samme, lik'],
    ['‘evengan', 'boy', 'gutt'],
    ['nìpxay', 'many', 'mange'],
    ['mäkxu', 'interrupt', 'avbryte'],
    ['kxakx', 'break, snap in two', 'bryte, knekke i to'],
    ['snaytx', 'lose', 'miste'],
    ['sop', 'travel', 'reise'],
    ['leronsrel', 'imaginary', 'imaginær'],
    ['kxener', 'smoke', 'røyk'],
    ['tì’eylan', 'friendship', 'vennskap'],
    ['nì’ul’ul', 'increasingly, more and more', 'stadig, mer og mer'],
    ['nìfkeytongay', 'actually, as a matter of fact', 'faktisk, som et spørsmål om faktum'],
    ['nìfe’', 'badly', 'dårlig'],
    ['letrrtrr', 'ordinary', 'ordinære'],
    ['tìsteftxaw', 'examination', 'undersøkelse'],
    ['natkenong', 'for example, as an example', 'for eksempel, som et eksempel'],
    ['munge', 'take, bring', 'ta, bringe'],
    ['fyel', 'seal, make impervious', 'forsegle, gjør ugjennomtrengelig'],
    ['ngopyu', 'creator', 'skaperen'],
    ['nget', 'smell of decaying wood and leaves', 'lukten av råtnende tre og blader'],
    ['tìsla’tsu', 'description', 'beskrivelse'],
    ['pängkxoyu lekoren', 'lawyer', 'advokat'],
    ['latsi', 'keep up with someone', 'holde tritt med noen'],
    ['ftxozä', 'celebration, happy occasion', 'feiring, lykkelig anledning'],
    ['io', 'over, above', 'over, ovenfor'],
    ['mawey', 'calm', 'rolig'],
    ['akrrmaw', 'after (temporal)', 'etter (temporal)'],
    ['fmetok', 'test', 'test'],
    ['nìlun', 'of course', 'selvfølgelig'],
    ['säftxulì’u', 'speech, oration', 'tale'],
    ['kew', 'zero', 'null'],
    ['onlor', 'goodsmelling', 'goodsmelling'],
    ['sìlpey', 'hope', 'håper'],
    ['omum', 'know', 'vet'],
    ['hìtxoa', 'excuse me', 'unnskyld meg'],
    ['kekem', 'no action or activity', 'ingen handling eller aktivitet'],
    ['hìmpxì', 'minority, least, small part', 'mindretall, minst, liten del'],
    ['fralo', 'every time, every instance, each time', 'hver gang, hver forekomst, hver gang'],
    ['säfyep', 'handle', 'håndtere'],
    ['lì’upuk', 'dictionary', 'ordbok'],
    ['nì’eng', 'equally, on the same level', 'likt, på samme nivå'],
    ['mesrram', 'the day before yesterday, two days ago', 'i forgårs, for to dager siden'],
    ['pak', 'disparagement', 'nedvurdering'],
    ['wokau', 'pendulum drum', 'pendeltromme'],
    ['‘upxare', 'message', 'beskjed'],
    ['kllkulat', 'dig up', 'grave opp'],
    ['tìmyì', 'low level', 'lavt nivå'],
    ['mrr', 'five', 'fem'],
    ['eyktan', 'leader', 'leder'],
    ['ko’on', 'ring, oval, closed shape roughly circular', 'ring, oval, lukket form omtrent sirkulær'],
    ['trrmuve', 'Monday', 'Mandag'],
    ['fratrr', 'daily, every day', 'daglig, hver dag'],
    ['lawnol', 'great joy', 'stor glede'],
    ['‘ìp', 'disappear, vanish', 'forsvinne'],
    ['matsa', 'crackers', 'kjeks'],
    ['sätare', 'connection, relationship', 'forhold, forbindelse'],
    ['zir', 'touch, feel', 'berøring, følelse'],
    ['tì’o’', 'fun, excitement', 'moro, spenning'],
    ['zo', 'be well', 'være vel'],
    ['nìsung', 'besides', 'foruten'],
    ['way a rol', 'sung poem', 'syngende dikt'],
    ['swawtsyìp', 'little moment, second', 'lite øyeblikk, andre'],
    [
        'nìtsim',
        'originally, in an original way, with originality',
        'opprinnelig, på en original måte, med originalitet'
    ],
    ['yayotsrul', 'bird’s nest', 'fuglereir'],
    ['kuma', 'that (as a result)', 'at (som et resultat)'],
    ['sìltsan', 'good (of its kind), well done', 'godt, bra gjort'],
    ['sähena', 'container, vessel, carrier', 'container, fartøy, transportør'],
    ['slu', 'become', 'bli'],
    ['‘ewan', 'young', 'ung'],
    ['ninan', 'by reading', 'ved lesing'],
    ['txele', 'matter (subject)', 'materie (emne)'],
    ['syor', 'relax, chill out', 'slappe av'],
    ['frawzo', 'all is well', 'alt er bra'],
    ['kangay', 'valid', 'gyldig'],
    ['tsengo', 'somewhere', 'et sted'],
    ['tsulfätu', 'master of an art, craft, or skill, expert', 'kunstemester, ekspert'],
    ['ekxan', 'barricade, obstruction', 'barrikade, obstruksjon'],
    ['‘ele’wll', 'cactus', 'kaktus'],
    ['yeyfya', 'straight line', 'rett linje'],
    ['yo’', 'be perfect, flawless', 'være perfekt, feilfri'],
    ['tsìk', 'suddenly, without warning', 'plutselig, uten forvarsel'],
    ['pxek', 'kick, shove', 'sparke, skubb'],
    ['fwi', 'slip, slide', 'skli'],
    ['kxamtseng', 'center, place in the middle', 'sentrum, sted i midten'],
    ['lì’upe', 'what!', 'hva!'],
    ['tolel', 'Eureka! I found it! I understand!', 'Eureka! Jeg fant den! Jeg forstår!'],
    ['nrra', 'pride', 'stolthet'],
    ['ro’a', 'be impressive', 'være imponerende'],
    ['swotu', 'sacred place', 'hellig sted'],
    ['skxir', 'wound', 'sår'],
    ['pxey', 'three', 'tre'],
    ['‘awpo', 'one individual', 'ett individ'],
    ['tsngawvìk', 'cry, weep', 'gråte, hulke'],
    ['fwem', 'dull, blunt (as a point)', 'stump, sløv '],
    ['txanwawe', 'personally meaningful, significant', 'personlig meningsfylt, betydelig'],
    ['käpxì', 'rear (part or section)', 'bak (del eller seksjon)'],
    ['tsakrr', 'then, at that time', 'da, på den tiden'],
    ['sngel', 'garbage', 'søppel'],
    ['tìkin', 'need', 'trenge'],
    ['kinamtil', 'knee', 'kne'],
    ['ke’u', 'nothing', 'ingenting'],
    ['volve', 'eighth (ordinal)', 'åttende (ordens)'],
    ['paysena', 'water container', 'vannbeholder'],
    ['fyin', 'simple', 'enkel'],
    ['soaia', 'family', 'familie'],
    ['tìfkeytok', 'state, condition, situation', 'tilstand, situasjon'],
    ['hona', 'endearing, adorable, cute', 'søt'],
    ['tirea', 'spirit', 'ånd'],
    ['rimpin', 'yellow', 'gul'],
    ['lrrtok si', 'smile', 'smil'],
    ['atxkxe', 'land', 'land'],
    ['tsmukan', 'brother', 'bror'],
    ['yawo', 'take off, launch', 'ta av'],
    ['nìsyen', 'lastly', 'til slutt'],
    ['ye’krr', 'early', 'tidlig'],
    ['ingyentsyìp', 'trick', 'trick'],
    ['ftang', 'stop', 'stoppe'],
    ['snapamrelvi', 'alphabet', 'alfabet'],
    ['tìkawng', 'evil', 'onde'],
    ['hewne', 'soft', 'myk'],
    ['venzek', 'toe', 'tå'],
    ['trrpuve', 'Friday', 'Fredag'],
    ['ayoeng', 'we', 'vi'],
    ['nìtkanluke', 'accidentally, unintentionally', 'uhell, utilsiktet'],
    ['zene', 'must', 'må'],
    ['tafral', 'therefore, because of that', 'derfor, på grunn av at'],
    ['kam', 'ago', 'siden'],
    ['txum', 'poison', 'gift'],
    ['za’u', 'come', 'komme'],
    ['weyn', 'draw, illustrate', 'tegne, illustrere'],
    ['ye', 'satisfied', 'fornøyd'],
    ['nìkanu', 'in a smart way', 'på en smart måte'],
    ['sa’nok', 'mother', 'mor'],
    ['ftia', 'study', 'studie'],
    ['maweypey', 'be patient', 'vær tålmodig'],
    ['starsìm', 'gather, collect', 'samle'],
    ['tatlam', 'apparently', 'tilsynelatende'],
    ['hefitswo', '(sense of) smell', '(følelse) lukt'],
    ['pukap', 'six', 'seks'],
    ['sat', 'that', 'det'],
    ['lun', 'reason', 'årsak'],
    ['tute', 'person', 'person'],
    ['somtìlor', 'hot beauty', 'sexy skjønnhet'],
    ['‘aku', 'take off', 'ta av'],
    ['tsalsungay', 'nevertheless', 'likevel'],
    ['yomhì’ang', 'small animal/insect eater', 'lite dyr, insektspiser'],
    ['hufwetsyìp', 'breeze', 'bris'],
    ['‘ite', 'daughter', 'datter'],
    ['vaykrr', 'until', 'inntil'],
    ['teyr', 'white', 'hvit'],
    ['numtsengvi', 'classroom, division of a school', 'klasserom'],
    ['pxan', 'worthy', 'verdig'],
    ['tsyänel', 'TV channel', 'TV-kanal'],
    ['somwewpe', 'what temperature?', 'hvilken temperatur? '],
    ['kinam', 'leg', 'ben'],
    ['‘awstengyem', 'bring two or more things together', 'bringe sammen to eller flere ting'],
    ['teya si', 'fill', 'fylle'],
    ['tìrey', 'life', 'livet'],
    ['pxisre', 'right before', 'rett før'],
    ['sloa', 'wide', 'bred'],
    ['pewn', 'neck', 'hals'],
    ['kawngsar', 'exploit', 'utnytte'],
    ['syaw', 'call', 'samtale'],
    ['vawt', 'solid, not hollow', 'solid, ikke hul'],
    ['leioae si', 'to respect', 'å respektere'],
    ['tsongtsyìp', 'dimple', 'smilehull'],
    ['nìtengfya', 'as, same way as (similar action)', 'som, på samme måte som (lignende handling)'],
    ['txanew', 'greedy', 'grådig'],
    [
        'atxar',
        'smell of living animals, as found around a watering hole',
        'lukten av levende dyr, som finnes rundt et vannhull'
    ],
    ['kip', 'among', 'blant'],
    ['um', 'loose', 'løs'],
    ['snomo', 'private space that one can retreat to', 'privat område man kan trekke seg tilbake til'],
    ['‘ampi', 'touch', 'berøre'],
    ['karyu', 'teacher', 'lærer'],
    ['nìlaw', 'clearly', 'klart'],
    ['holpxaype', 'how many', 'hvor mange'],
    ['nìhawng', 'too, excessively', 'for mye, overdrevet'],
    ['tsongropx', 'hole, cavity', 'hull, hulrom'],
    ['‘a’aw', 'a few, several', 'noen, flere'],
    ['paywll', 'water plant', 'vannanlegg'],
    ['frapo', 'everyone', 'alle'],
    ['nìteng', 'also', 'også'],
    ['‘anla', 'yearn for', 'lengte etter'],
    ['we’ay', 'sour', 'sur'],
    ['mun’i', 'cut', 'kutt'],
    ['pamrel si', 'write', 'skrive'],
    ['rangal', 'wish', 'ønske'],
    ['ka', 'across', 'tvers'],
    ['fula', 'that', 'det'],
    ['txey', 'halt', 'stans'],
    ['rawn', 'replace', 'erstatte'],
    ['eyk', 'lead', 'føre'],
    ['txon’ong', 'nightfall', 'skumring'],
    ['‘em', 'cook', 'kokk'],
    ['lerìk', 'leafy', 'løvete'],
    ['tsaheylu', 'bond (neural connection)', 'bånd (nevral tilkobling)'],
    ['nìmun', 'again', 'igjen'],
    ['‘awkx', 'cliff', 'klippe'],
    ['nokx', 'give birth to', 'føde'],
    ['säspxintsyìp', 'minor ailment', 'mindre sykdom'],
    ['tìwusem', 'fighting', 'slåssing'],
    ['tìn', 'activity that keeps one busy', 'aktivitet som holder en travel'],
    ['ha', 'in that case', 'i så fall'],
    ['ya', 'air', 'luft'],
    ['sran', 'yes', 'ja'],
    ['teylu', 'beetle larva', 'billelarve'],
    ['herwì', 'snow', 'snø'],
    ['hasey si', 'accomplish', 'oppnå'],
    ['kxll si', 'charge', 'lade'],
    ['sätswayon', 'flight', 'flygning'],
    ['tätxaw', 'return', 'retur'],
    ['tìftxey', 'choice', 'valg'],
    ['nìfrakrr', 'as always', 'som alltid'],
    ['nì’it', 'a bit, a small amount', 'litt, en liten mengde'],
    ['tengfya', 'as, same way as', 'som, på samme måte som'],
    ['krrpe', 'when', 'når'],
    ['tskalep', 'crossbow', 'armbryst'],
    ['ramunong', 'well', 'vel'],
    ['nari si', 'be careful', 'vær forsiktig'],
    ['kaltxì si', 'say hello, greet', 'si hallo, hilse'],
    ['sngumtsim', 'worrisome matter, source of worry', 'bekymringsfull sak, kilde til bekymring'],
    ['letokx', 'bodily, physical', 'kroppslig, fysisk'],
    ['säwäsul', 'a competition', 'en konkurranse'],
    ['tsampongu', 'war party', 'krigergruppe'],
    ['tare', 'connect', 'koble'],
    ['syeha si', 'breathe', 'puste'],
    ['nìsngum', 'worryingly, fretfully', 'bekymringsfullt'],
    ['nìyeyfya', 'straight ahead, in a straight line', 'rett frem, i en rett linje'],
    ['takrra', 'since (time related)', 'siden (tidsbestemmelse)'],
    ['txeptseng', 'place where a fire is burning or has burned', 'sted hvor en brann brenner eller har brent'],
    ['slan', 'support (emotional, social or personal support)', 'støtte (emosjonell, sosial eller personlig støtte)'],
    ['fìtsap', 'each other', 'hverandre'],
    ['nìwotx', 'all (of), in toto, completely', 'alle, helt'],
    ['tukru', 'spear', 'spyd'],
    ['swok', 'sacred', 'hellig'],
    ['loi', 'egg', 'egg'],
    ['pìmtxan', 'how much', 'hvor mye'],
    ['nìmeyp', 'weakly', 'svakt'],
    ['sä’ipu', 'something humorous', 'noe humoristisk'],
    ['sänrr', 'glow, an instance of glowing', 'glød, en forekomst av glødende'],
    ['fkay', 'hateful', 'hatsk'],
    ['rey’eng', 'the Balance of Life', 'livets balanse'],
    ['alu', 'that is, in other words', 'det er med andre ord'],
    ['sti', 'anger, be angry', 'sinne, være sint'],
    ['trrpxeyve', 'Tuesday', 'Tirsdag'],
    ['spono', 'island', 'øy'],
    ['sekrr', 'present', 'til stede'],
    ['lor', 'beautiful, pleasant to the senses', 'vakker, behagelig for sansene'],
    ['smon', 'be familiar, be known with', 'være kjent, bli kjent med '],
    ['tìkan', 'aim, goal, purpose, target', 'mål, formål'],
    ['tìmweypey', 'patience', 'tålmodighet'],
    ['nìsoaia', '(together) as members of a family', '(Sammen) som medlemmer av en familie'],
    ['reypay', 'blood', 'blod'],
    ['ìlva', 'flake, drop, chip', 'dråpe'],
    ['ukxo', 'dry', 'tørr'],
    ['walak', 'active', 'aktiv'],
    ['nìftxavang', 'passionately, with all heart', 'lidenskapelig, med hele hjertet'],
    ['kxaylyì', 'high level', 'høyt nivå'],
    ['hum', 'leave, depart', 'forlate'],
    ['txan', 'much', 'mye'],
    ['wo', 'reach for', 'strekke seg etter'],
    ['lenrra', 'proud', 'stolt'],
    ['eltur tìtxen si', 'be interesting, intriguing', 'være interessant, spennende'],
    ['tìkakrel', 'blindness', 'blindhet'],
    ['atxkxerel', 'map', 'kart'],
    ['tìrol', 'song (vocal)', 'sang (vokal)'],
    ['pamrelvi', 'letter (phonetic symbol)', 'bokstav (fonetisk symbol)'],
    ['käsatseng', 'out there', 'der ute'],
    ['hawtsyìp', 'nap', 'en lur'],
    ['leyewla', 'disappointing', 'skuffende'],
    ['kelku si', 'live, dwell', 'leve, bo'],
    ['ye’rìn', 'soon', 'snart'],
    ['yayo', 'bird', 'fugl'],
    ['käsrìn', 'lend', 'låne ut'],
    ['mìso', 'away (position)', 'unna (posisjon)'],
    ['kelsar', 'useless, in vain', 'ubrukelig, forgjeves'],
    ['emza’u', 'pass (a test), overcome', 'bestå (en test), overvinne'],
    ['yìm', 'bind', 'bind'],
    ['fe’ul', 'get worse', 'bli verre'],
    ['vll', 'indicate, point at', 'indikere, peke på'],
    ['swirä', 'creature', 'skapning'],
    ['mokri', 'voice', 'stemme'],
    ['tsyìräfì', 'giraffe', 'giraff'],
    ['tìterkup', 'death (in abstract sense)', 'død (i abstrakt forstand)'],
    ['venu', 'foot', 'fot'],
    ['ingyentsim', 'mystery, riddle, enigma, conundrum', 'mysterium, gåte'],
    ['mekre', 'supplies', 'forsyninger'],
    ['kxukx', 'swallow', 'svelge'],
    ['nga', 'you', 'du'],
    ['akum', 'that (as a result)', 'at (som et resultat)'],
    ['srese’a', 'predict', 'forutsi'],
    ['nguway', 'howl', 'hyle'],
    ['klltseng', 'position', 'posisjon'],
    ['neto', 'away (direction)', 'unna (retning)'],
    ['tsenge', 'place', 'sted'],
    ['hawnven', 'shoe', 'sko'],
    ['muntrram', 'last weekend', 'forrige helg'],
    ['taronyutsyìp', 'little hunter', 'liten jeger'],
    ['ne’ìm', 'back (direction)', 'tilbake (retning)'],
    ['tìpängkxotsyìp', 'little conversation', 'liten samtale'],
    ['nulnew', 'prefer', 'foretrekke'],
    ['way', 'song (ancient, ceremonial)', 'sang (gammel, seremoniell)'],
    ['mi', 'yet, still, as before', 'ennå, likevel, som før'],
    ['mawkrr', 'after, afterwards', 'etter, etterpå'],
    ['nrr', 'glow ', 'gløde'],
    ['zey', 'special, distinct', 'spesiell, tydelig'],
    ['fra’u', 'everything', 'alt'],
    ['may’', 'try', 'prøve'],
    ['‘Rrta', 'Earth', 'Jorden'],
    ['hufwa', 'although', 'selv'],
    ['sto', 'refuse (to do something)', 'nekte (å gjøre noe)'],
    ['tìkangkem', 'work', 'arbeid'],
    ['salewfya', 'direction, course', 'retning, kurs'],
    ['len', 'happen, occur', 'skje, skje'],
    ['kìng', 'thread', 'tråd'],
    ['nìpxim', 'rightly', 'med rette'],
    ['frrtu', 'guest, visitor', 'gjest, besøkende'],
    ['wutso', 'dinner, served meal', 'middag, servert måltid'],
    ['nìsti', 'angrily', 'sint'],
    ['stxenutìng', 'offer', 'tilbud'],
    ['kar', 'teach', 'undervise'],
    ['tam', 'that will suffice, that will do, OK', 'det er nok, det holder, ok'],
    ['säspxin', 'disease, sickness, illness', 'sykdom'],
    ['ftawnemkrr', 'past', 'over'],
    ['tsyafe', 'mild, moderate, comfortable', 'mild, moderat, komfortabel'],
    ['mungwrr', 'except', 'unntatt'],
    ['spulmokri', 'telephone', 'telefon'],
    ['payfya', 'stream', 'stream'],
    ['nìnu', 'in vain', 'forgjeves'],
    ['lini', 'young animal', 'ung dyr'],
    ['fewtusok', 'opposite', 'motsatt'],
    ['li', 'already', 'allerede'],
    ['mesrray', 'the day after tomorrow, two days from now', 'dagen etter i morgen, to dager fra nå'],
    ['muntrray', 'next weekend', 'neste uke'],
    ['virä', 'spread', 'spread'],
    ['yayayr', 'confusion', 'forvirring'],
    ['tìmuntxa', 'marriage', 'ekteskap'],
    ['kxitx', 'death', 'død'],
    ['polpxay', 'how many', 'hvor mange'],
    ['lesar', 'useful', 'nyttig'],
    ['hoet', 'vast, broad, expansive', 'store, bred, ekspansiv'],
    ['sempul', 'father', 'far'],
    ['kxamtxon', 'midnight', 'midnatt'],
    ['pxi', 'sharp (as a point)', 'skarp (som et punkt)'],
    ['velke', 'messy', 'rotete'],
    ['hawnu', 'shelter', 'ly'],
    ['ngul', 'grey', 'grå'],
    ['pamrelfya', 'spelling', 'staving'],
    ['nafì’u', 'such', 'slik'],
    ['flì', 'thin', 'tynn'],
    ['zusawkrr', 'future', 'fremtid'],
    ['fkxile', 'necklace', 'halskjede'],
    ['tìfmetok', 'test', 'test'],
    ['sau', 'exclamation upon exertion, urgh', 'utrop ved anstrengelse, urgh'],
    ['txoa', 'forgiveness', 'tilgivelse'],
    ['tsun', 'can, be able', 'kan, kunne'],
    ['txonkrr', 'at night', 'om natten'],
    ['kum', 'result', 'resultat'],
    ['txula', 'build, construct', 'bygge, konstruere'],
    ['rofa', 'beside, alongside', 'ved siden av, sammen'],
    ['nìwä', 'on the contrary, conversely', 'tvert imot, omvendt'],
    ['lu', 'be', 'være'],
    ['ngrr', 'root', 'rot '],
    ['tìng mikyun', 'listen', 'lytte'],
    ['nìyrr', 'naturally', 'naturlig'],
    ['‘eylan', 'friend', 'venn'],
    ['tìkangkem si', 'work', 'arbeid'],
    ['kllvawm', 'brown', 'brun'],
    ['rutxe', 'please', 'vær så snill'],
    ['ralnga’', 'meaningful', 'meningsfull'],
    ['salew', 'proceed, go', 'fortsette, gå'],
    ['spaw', 'believe', 'tror'],
    ['mawkrra', 'after (time)', 'etter (tid)'],
    ['txanatan', 'bright, vivid', 'klart og levende'],
    ['tì’awm', 'camping', 'camping'],
    ['säkanom', 'something acquired', 'noe ervervet'],
    ['tup', 'instead of, rather than', 'i stedet for'],
    ['tskxekeng', 'training, exercise', 'trening'],
    ['nì’eveng', 'like a child, immaturely', 'som et barn, umodent'],
    ['tìska’a', 'destruction', 'ødeleggelse'],
    ['pximaw', 'right after', 'rett etter'],
    ['wätx', 'be bad at', 'være dårlig i'],
    ['hìmtxewvay', 'minimally', 'minimalt'],
    ['kem si', 'do', 'gjøre'],
    ['rel arusikx', 'movie', 'film'],
    ['lom', 'missing', 'mangler'],
    ['kìm', 'to spin', 'å spinne'],
    ['skepek', 'formal', 'formell'],
    ['ngaytxoa', 'sorry', 'beklager'],
    ['ngeyn', 'tired', 'trøtt'],
    ['sngunga’', 'troubling', 'problemfullt'],
    ['klltxay', 'lie on the ground', 'ligge på bakken'],
    ['lehrrap', 'dangerous', 'farlig'],
    ['kìmar', 'in season (of foods, vegetable or animal)', 'i sesongen (av matvarer, vegetabilsk eller animalsk)'],
    ['‘ia', 'lose oneself (spiritual sense)', 'miste seg selv (åndelig forstand)'],
    ['fte', 'so that, in order to', 'slik at, for å kunne'],
    ['slosnep', 'width', 'bredde'],
    ['pxiye’rìn', 'immediately', 'umiddelbart'],
    ['tìtstewnga’', 'brave', 'modig'],
    ['layon', 'black', 'svart'],
    ['fyinep’ang', 'degree of complexity', 'grad av kompleksitet'],
    ['srane', 'yes', 'ja'],
    ['yem', 'place', 'sted'],
    ['ftxozä si', 'celebrate', 'feire'],
    ['kä’ärìp', 'push', 'dytte'],
    ['ne', 'towards', 'mot'],
    ['eltungawng', 'brainworm', 'hjerneorm'],
    ['prrte’', 'pleasurable', 'behagelig'],
    ['mìfa', 'inside', 'inne'],
    ['tì’i’avay', 'until the end', 'til slutten'],
    ['sweylu', 'should', 'bør'],
    ['san', 'saying', 'sier'],
    ['tstunwi', 'kind', 'snill'],
    ['tìng tseng', 'back down', 'tre ned'],
    ['pamrelsiyu', 'writer', 'forfatter'],
    ['nìtam', 'enough', 'nok'],
    ['sätsìsyì', 'whisper', 'hviske'],
    ['nìyo’', 'perfectly', 'perfekt'],
    ['‘evenge', 'girl', 'jente']
];
