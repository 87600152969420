import QuizItem from '@/modules/games-shared/components/QuizItem';

import Session from './Session';
import questionService from './question-service';
import Card from './components/Card';
import MemorizeItemInstruction from './components/MemorizeItemInstruction';
import Question from './components/Question';
import WordTest from './components/WordTest';

const quizMapping = {
    'GameVocabguru-MemorizeItem': {
        component: Card,
        instructionComponent: MemorizeItemInstruction
    },
    'GameVocabguru-QuizItem': {
        component: QuizItem,
        questionComponent: Question,
        generateTestNames(data) {
            const testNames = [];
            testNames.push('wordTest');
            return testNames;
        },
        tests: {
            wordTest: {
                component: WordTest,
                generateTestData(data) {
                    const fasit = data.a.word.toLowerCase();
                    const fragments = questionService.getWordFragments(12, [fasit]);
                    return {
                        fasit,
                        type: 'MultipleChoice',
                        requiredChoiceCount: 2,
                        fragments
                    };
                },
                calculateScore(data, answer) {
                    return data.a.word.toLowerCase() === answer ? 1 : 0;
                }
            }
        }
    }
};

export default {
    moduleName: 'moduleGameVocabguru',
    Session,
    quizMapping
};
