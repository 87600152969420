<template>
  <div class="Card">
    <div class="spacerTop" />
    <div class="card">
      <div class="label">
        {{ $t(`currentGame.${b.language}`) }}
      </div>
      <div class="word">
        {{ b.word }}
      </div>
      <div class="divider" />
      <div class="label">
        {{ $t(`currentGame.${a.language}`) }}
      </div>
      <div class="word">
        {{ a.word }}
      </div>
    </div>
    <div class="spacerBottom" />
  </div>
</template>

<script>
export default {
    props: ['a', 'b']
};
</script>

<style scoped lang="scss">
.Card {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.spacerTop {
    flex: 0.3;
}
.spacerBottom {
    flex: 1;
}
.card {
    padding: 2em 1em;
    background-color: white;
    color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 20em;
    box-shadow: 0 1em 1em rgba(black, 0.2);
    border-radius: 0.2em;
}
.label {
    color: rgba(black, 0.3);
    margin-bottom: 0.5em;
}
.divider {
    height: 1px;
    background-color: rgba(black, 0.05);
    margin: 2em 0;
}
.word {
    line-height: 1.5em;
    color: mix(black, white, 80%);
    font-size: 160%;
    font-weight: 600;
}
</style>
