export default [
    ["ghaytanHa'", 'unlikely', 'usannsynlig'],
    ['ghIq', 'then', 'deretter'],
    ['je', 'also', 'også'],
    ["nItebHa'", 'together', 'sammen'],
    ['ngugh', 'then', 'deretter'],
    ['qen', 'recently', 'nylig'],
    ["tagha'", 'finally', 'endelig'],
    ['tlhoy', 'excessively', 'overdrevent'],
    ["ghuy'cha'", 'a curse', 'en forbannelse'],
    ['mevyap', 'to stop', 'å stoppe'],
    ['pItlh', 'done', 'gjort'],
    ["Qu'vatlh", 'a curse', 'en forbannelse'],
    ['tugh', 'to hurry up', 'å skynde seg'],
    ['baghneQ', 'spoon', 'skje'],
    ['baHjan', 'launcher', 'igangsetter'],
    ['batlh', 'honor', 'ære'],
    ['beb', 'roof', 'tak'],
    ['bertlham', 'end', 'endelig'],
    ["betleH bey'", 'display', 'utstilling'],
    ["be'joy'", 'torture by women', 'tortur fra kvinner'],
    ['bIQ', 'water', 'vann'],
    ['bIQSIp', 'hydrogen', 'hydrogen'],
    ["bIS'ub", 'bottom  ', 'bunn'],
    ["bI'reS", 'beginning', 'begynnelse'],
    ['bo', 'feather', 'fjær'],
    ['bop', 'to be concerned', 'å være bekymret'],
    ['boq', 'alliance', 'allianse'],
    ["bo'Degh", 'bird', 'fugl'],
    ['butlh', 'fingernail-dirt', 'negl-skitt'],
    ["chaDvay'", 'hertz', 'hertz'],
    ['chan', 'eastward', 'østover'],
    ["chang'eng", 'pair', 'par'],
    ["chaw'", 'permit', 'tillatelse'],
    ["cha'DIch", 'second', 'andre'],
    ['cheb', 'weight unit', 'vektenhet'],
    ["chevwI' tlhoy'", 'territorial wall', 'grensevegg'],
    ['chob', 'corridor', 'korridor'],
    ["chob'a'", 'main corridor', 'hovedkorridor'],
    ['choQ', 'ship deck', 'skipsdekk'],
    ["chuq'a'", 'long range', 'lang rekkevidde'],
    ['DaH', 'bank', 'bank'],
    ['DavHam', 'false honor', 'falsk ære'],
    ['DenIb Qatlh', 'slime devil', 'slimdjevel'],
    ['DIn', 'entryway', 'portrom'],
    ['Dol', 'entity', 'enhet'],
    ['Duran lung DIr', 'lizard skins', 'øgleskinn'],
    ["ghangwI'", 'horizon', 'horisont'],
    ['gheb', 'horn', 'horn'],
    ["ghetwI'", 'pretender', 'hykler'],
    ["ghItlhwI'", 'stylus', 'fyllepenn'],
    ["ghochwI'", 'tracker', 'tracker'],
    ["ghogh HablI'", 'telephone', 'telefon'],
    ['ghojmeH taj', "boy's knife", 'guttekniv'],
    ["ghotI'", 'fish', 'fisk'],
    ['ghubDaQ', 'first-born', 'førstefødte'],
    ["Heghba'", 'suicide', 'selvmord'],
    ['Hoch', 'everyone', 'alle'],
    ['HolQeD', 'journal', 'journal'],
    ["HomwI'", 'second toe', 'andre tå'],
    ['Hu', 'zoo', 'zoo'],
    ['HuchQeD', 'economics', 'økonomi'],
    ['Hutvagh', 'crowded', 'fylt'],
    ["jaghla'", 'enemy commander', 'fiendekommandør'],
    ['jaj', 'day', 'dag'],
    ['jajvam', 'today', 'i dag'],
    ['jolpat', 'transporter system', 'transportsystem'],
    ['jornub', 'warhead', 'stridshode'],
    ['lev', 'roof of mouth', 'gane'],
    ['lIr', 'night bird', 'nattfugl'],
    ['logh', 'space', 'plass'],
    ['loghqam', 'light year unit', 'lysårsenhet'],
    ['lol', 'martial arts position', 'kampsportposisjon'],
    ['lor', 'niece or nephew', 'niese eller nevø'],
    ["lo'laHbe'ghach", 'worthlessness', 'verdiløshet'],
    ["lo'laHghach", 'value', 'verdi'],
    ['maQmIgh', 'dark omen', 'dystert tegn'],
    ["marwI'", 'big toe', 'stortå'],
    ["ma'veq", 'ceremonial knife', 'seremoniell kniv'],
    ["megh'an", 'end of stick', 'slutten av pinne'],
    ["meqba'", 'legal proceeding', 'rettslige prosesser'],
    ["meyrI'", 'square', 'firkantet'],
    ["me'", "mother's sister", 'mors søster'],
    ["me'nal", "mother's brother's wife", 'mors brors kone'],
    ["mu'qaD veS", 'curse warfare', 'forbannelseskrigføring'],
    ['naHjej', 'thistle', 'tistel'],
    ['namtun', 'start of song', 'start av sang'],
    ["nanwI'", 'fourth toe', 'fjerde tå'],
    ['natlIS', 'last item in a list', 'siste elementet i en liste'],
    ["nay'", 'course', 'kurs'],
    ['neb', 'beak', 'nebb'],
    ["neSlo'", 'small mirror', 'lite speil'],
    ["notqa'", 'black bird', 'svart fugl'],
    ["nuH bey'", 'pride of weapons', 'stolthet av våpen'],
    ['ngav', "writer's cramp", 'skrivekrampe'],
    ['ngech', "valley [woman's cleavage]", 'dal [kvinnes kløft]'],
    ['ngop', 'plates', 'plater'],
    ["ngoqDe'", 'encoded message', 'kodet melding'],
    ["nguSDI'", 'motor', 'motor'],
    ['pach', 'claw', 'klo'],
    ['parbIng', 'mid-sized bird', 'mellomstor fugl'],
    ["pa'", 'chamber', 'kammer'],
    ["pa' beb", 'ceiling', 'tak'],
    ["pa' reD", 'interior wall', 'innervegg'],
    ['peghmey vIttlhegh', 'secrecy proverb', 'hemmelighetsordtak'],
    ['pIq', 'time period   ', 'tidsperiode'],
    ['pIrmuS', 'bottom  ', 'bunn'],
    ['pIvchem', 'warp field', 'warp-feltet'],
    ['pIvlob', 'warp factor', 'warp faktor'],
    ['pop', 'honor', 'ære'],
    ['porghQeD', 'body study', 'kroppsstudier'],
    ["pujwI'", 'weakling', 'svekling'],
    ['puq chonnaQ', 'fork', 'gaffel'],
    ["puqnI'", 'grandchild', 'barnebarn'],
    ["puqnI'be'", 'granddaughter', 'barnebarn (jente)'],
    ["puqnI'loD", 'grandson', 'barnebarn (gutt)'],
    ['qab', 'face', 'ansikt'],
    ['qanraD', 'song bird', 'sangfugl'],
    ["qanwI'", 'pinky', 'lillefinger'],
    ['qaryoq', 'talking bird', 'snakkende fugl'],
    ["qaywI'", 'middle finger', 'langfinger'],
    ["qa'meH", 'replacement', 'erstatning'],
    ["qa'rI'", 'end', 'ende '],
    ["qa'rol", 'big bird', 'stor fugl'],
    ["qa'vaQ", 'a game', 'et spill'],
    ["qewwI'", 'ring finger', 'ringfinger'],
    ["qeylIS mInDu'", 'guessing game', 'gjettelek'],
    ["qo'qaD", 'phlegm', 'slim'],
    ['qughDo', 'cruising speed', 'marsjfart'],
    ['qul tuq', 'House', 'hus'],
    ['qung', 'musical instrument', 'musikkinstrument'],
    ['qutluch', 'type of hand weapon', 'type håndvåpen'],
    ['quv', 'honor', 'ære'],
    ["quv bey'", 'honor display', 'ærefull adferd'],
    ["quy'Ip", 'vomit', 'å spy'],
    ['Qaj', 'type of animal with brown lips', 'type dyr med brune lepper'],
    ["Qay'wI'", 'little toe, fifth toe', 'lilletå, femte tå'],
    ["Qep'It", 'any gas produced by a body', 'enhver gass som produseres av et legeme'],
    ['QIn', 'spear head, sharpened end of pencil', 'spydspiss'],
    ["QI'lop", 'a holiday', 'en ferie'],
    ["QI'tu'", 'paradise', 'paradis'],
    ['QongDaqDaq', 'in bed', 'i senga'],
    ["Qulpa'", 'research lab', 'forskningslaboratorium'],
    ['Qum', 'communication', 'kommunikasjon'],
    ['Qun', 'god, supernatural being', 'gud, overnaturlig vesen'],
    ["rachwI'", 'nurse', 'sykepleier'],
    ["rav'eq", 'ceiling', 'tak'],
    ["raw'", 'an aquatic bird with colorful plumage', 'en vannlevende fugl med fargerik fjærdrakt'],
    ['reD', 'exterior wall', 'yttervegg'],
    ['ret', 'time period ago (past)', 'tidsperiode siden (siste)'],
    ["rIlwI'", 'thumb', 'tommel'],
    ["roSwI'", 'third toe', 'tredje tå'],
    ['rutlh', 'wheel', 'hjul'],
    ['Saqjan', 'landing gear', 'landingshjul'],
    ["Sa'Hut", 'ass, rear end, buttocks', 'rumpe'],
    ['Segh', 'race (type, sort, class)', 'rase'],
    ["SenwI'", 'thumb', 'tommel'],
    ["SenwI' rIlwI' je", 'everybody', 'alle'],
    ['Serrum', 'earwax', 'ørevoks'],
    ["SIla'", 'large mirror', 'stort speil'],
    ["SIqwI'", 'index finger, first finger', 'pekefinger'],
    ['Soj', 'food', 'mat'],
    ['SoSbor', 'motherboard', 'hovedkort'],
    ["SoSbor'a'", 'main core', 'hovedkjerne'],
    ['tajvaj', 'angle', 'vinkel'],
    ['tal', 'cannon', 'kanon'],
    [
        "tangqa'",
        'animal species with both genders resembling Terran bull',
        'dyrearter med begge kjønn (ligner Terra-okse)'
    ],
    ['targh tIq', 'heart of targ, a food item', 'hjertet av targ, en matvare'],
    ['tel', 'wing', 'fløy'],
    ['tennuS', "uncle, father's brother", 'onkel, fars bror'],
    ['tennuSnal', "uncle, father's sister's husband", 'onkel, fars søsters mann'],
    ["tepqengwI'", 'cargo lift', 'last løft'],
    ["tey'", 'male cousin', 'mannlig fetter'],
    ["tey'be'", 'female cousin', 'kvinnelig fetter'],
    ["tIjwI'ghom", 'boarding party', 'ombordstigningsfest'],
    ['tIng', 'southwestward', 'sørvestlig'],
    ['tIq', 'heart', 'hjerte'],
    ['toDDuj', 'rescue ship', 'redningsskip'],
    ['toQ', 'a bird of prey', 'en rovfugl'],
    ['tuqnIgh', "a member of one's house", 'et medlem av ens hus'],
    ["tlhach mu'mey", 'jargon', 'sjargong'],
    ["tlhepQe'", 'saliva', 'spytt'],
    ["tlhoy'", 'interior wall', 'innervegg'],
    ["tlhoy' SaS", 'ceiling/floor between stories', 'tak / gulv mellom etasjer'],
    ["tlho'ren", 'unit measure approx. one quart/litre', 'måleenhet ca. én liter'],
    ["vay'", 'somebody, something', 'noen, noe'],
    [
        "vem'eq",
        'a bird that feeds almost exclusively on serpent worm ',
        'en fugl som lever nesten utelukkende på slanger'
    ],
    ['veqlargh', 'devil, demon', 'djevel, demon'],
    ["verengan Ha'DIbaH", 'Ferengi dog - a classic insult', 'Ferengi hund - en klassisk fornærmelse'],
    ["vID'Ir", 'baffled', 'forbløffet'],
    ['vIlInHoD', 'bird capable of mimicking speech', 'fugl i stand til å etterligne tale'],
    ['vIn', 'cousin, niece or nephew', 'fetter, niese eller nevø'],
    ['vIq', 'battle, combat', 'kamp, ??kamp'],
    ["vItHay'", 'the truth test', 'sannheten test'],
    ['wab', 'sound, noise', 'lyd, støy'],
    ['waqboch', 'a bird with a very long beak', 'en fugl med svært lang nebb'],
    ['waQ', 'months from now', 'måneder fra nå'],
    ['wen', 'months ago', 'måneder siden'],
    ["wovmoHwI'", 'light (lamp)', 'lys (lampe)'],
    ["yaS cha'DIch", 'second officer', 'andreoffiser'],
    ["yaS wa'DIch", 'first officer', 'styrmann'],
    [
        'yatqap',
        'a gray bird that can travel particularly long distances ',
        'en grå fugl som kan reise spesielt lange avstander'
    ],
    ['yer', 'domain, holdings, territory', 'domene, beholdning, territorium'],
    ['yergho', 'wall of a city', 'veggen til en by'],
    ['yor', 'top (exterior)', 'toppen (utvendig)'],
    ["yo'SeH yaHnIv", 'Operations Command', 'operasjonskommando'],
    ["yuQjIjDIvI'", 'United Federation of Planets', 'Forbundet av Planetene'],
    ['yur', 'distant cousin, niece or nephew', 'fjern fetter, niese eller nevø'],
    ["yuvtlhe'", 'lid, cover, cap', 'lokk'],
    ["yu'egh", 'wave', 'bølge'],
    ['aqroS', 'ceiling', 'tak'],
    ['eDjen', 'an arrogant person', 'en arrogant person'],
    ['ev', 'northwestward', 'nordvestlig'],
    ["e'mam", "aunt, father's sister", 'tante, fars søster'],
    ["e'mamnal", "aunt, father's brother's wife", 'tante, fars brors kone'],
    ["e'nal", 'someone who married into the family', 'noen som giftet seg inn i familien'],
    ['IqnaH', 'mucus', 'slim'],
    ['IqnaH QaD', 'booger', 'busemann'],
    ['IrneH', "uncle, mother's brother", 'onkel, mors bror'],
    ['IrneHnal', "uncle, mother's sister's husband", 'onkel, mors søsters mann'],
    ['och', 'conduit', 'kanal'],
    ["orwI'", 'pilot', 'pilot'],
    ["o'megh", 'end (of a song)', 'slutten (av en sang)'],
    ['uD Haqtaj', 'laser scalpel', 'skalpellaser'],
    ["uD'a'", 'laser', 'laser'],
    ["urwI'", 'traitor', 'forræder'],
    ['uSgheb', 'a noisy bird ', 'en bråkete fugl'],
    ["DIvI'", 'federation', 'føderasjon'],
    ['eDSeHcha', 'Take-off', 'ta av'],
    ['maH', 'ten', 'ti'],
    ['netlh', 'ten thousand', 'ti tusen'],
    ['arlogh', 'how many times?', 'hvor mange ganger?'],
    ["bachHa'", 'to err', 'å feile'],
    ["boqHa'", 'subtraction', 'subtraksjon'],
    ["boqHa''egh", 'division', 'divisjon'],
    ["boq'egh", 'multiplication', 'multiplikasjon'],
    ['bor', 'gurgle', 'gurgle'],
    ['bur', 'hiccup', 'hikke'],
    ['chegh', 'return to', 'tilbake til'],
    ['chen', 'to take form', 'å ta form'],
    ['chuy', 'to sneeze', 'å nyse'],
    ['Dav', 'to sway', 'å svaie'],
    ["Doq 'ej wovbe'", 'be brown', 'å være brun'],
    ['Dor', 'end', 'end'],
    ["Do'Ha'", 'to be fortunate', 'å være heldig'],
    ['Dugh', 'to be vigilant', 'å være årvåken'],
    ['ghal', 'to be jealous (of)', 'å være sjalu (av)'],
    ['ghang', 'to end prematurely', 'å avslutte for tidlig'],
    ['ghet', 'to pretend', 'å late'],
    ['Hom', 'use the second toe', 'bruke andre tå'],
    ['HoQ', 'be honored falsely, be falsely honorable', 'hedres falskt, være feilaktig hederlig'],
    ['jev', 'storm, [wheeze, breathe noisily]', 'storme, [hvese, puste, brake]'],
    ['jIj', 'cooperate', 'samarbeide'],
    ['jIm', 'heave (aircraft rising or falling without pitching)', 'lempe (fly stigende eller fallende uten pitching)'],
    ['juch', 'have a width of', 'ha en bredde'],
    ['laq', 'flap', 'klaff'],
    ["law'", 'many', 'mange'],
    ['lIH', 'begin a song', 'begynne en sang'],
    [
        'lol',
        'be in an attitude (aircraft); be in a pose (martial arts)',
        'være i en holdning (fly); være i en positur (kampsport)'
    ],
    ["lolchu'", 'be in a correct attitude (aircraft)', 'være i en korrekt holdning (fly)'],
    ["lolchu'taH", 'maintain a correct attitude (aircraft)', 'opprettholde en riktig holdning (fly)'],
    ['lolmoH', 'maneuver (an aircraft) to be in an attitude', 'manøvrere (et luftfartøy) til å være i en holdning'],
    ['loltaH', 'maintain an attitude (aircraft)', 'opprettholde en holdning (fly)'],
    ['mar', 'use the big toe', 'bruke stortåen'],
    ['mIl', 'be formerly honored', 'være tidligere hedret'],
    ['motlh', 'be normal / standard', 'være normal / standard'],
    ['muj', 'be wrong', 'å ha feil'],
    ['nan', 'use the fourth toe', 'bruke den fjerde tå'],
    ['naQ', 'be full, whole', 'være full, hel'],
    ["nga'chuq", 'to have sex', 'å ha sex'],
    ['ngIj', 'to be rowdy', 'å være bølle'],
    ['nguq', 'to be arrogant', 'å være arrogant'],
    ['pegh', 'to be secret', 'å være hemmelig'],
    ['per', 'to label', 'å merke'],
    ['pujmoH', 'weaken', 'svekke'],
    ['pup', 'be high resolution', 'være i høy oppløsning'],
    ['pur', 'to inhale', 'å inhalere'],
    ['qaj', 'soar', 'sveve'],
    ['qan', 'use the pinky (little finger)', 'bruke lillefingeren'],
    ['qay', 'use the second (middle) finger', 'bruke midtfingeren'],
    ['qew', 'use the ring (third) finger', 'bruke ringfingeren'],
    ['Qaq', 'to behave in a falsely honorable manner', 'å oppføre seg på en feilaktig hederlig måte'],
    ["Qaw'", 'to destroy', 'å ødelegge'],
    ["Qay'", 'use the little toe', 'bruke lilletåen'],
    ['QID', 'wound', 'sår'],
    ['Qutlh', 'support', 'støtte'],
    ['rach', 'to strengthen', 'å styrke'],
    ['rech', 'to exhale', 'å puste'],
    ['rIl', 'play (a wind instrument)', 'spille (et blåseinstrument)'],
    ['ron', 'roll (aircraft wings tilt, one up, one down)', 'vippe vinger opp og ned (fly/romskip)'],
    ['roS', 'use the third toe', 'bruke den tredje tåen'],
    ['ruq', 'belch', 'oppstøt'],
    ["Say'qu'moH", 'sterilize', 'sterilisere'],
    ['Sen', 'use the thumb', 'bruke tommelen'],
    ['SIq', 'use the index finger', 'bruke pekefingeren'],
    ['SIv', 'to wonder', 'å lure på'],
    ['taQ', 'be weird', 'være rart'],
    ['tatlh', 'return (something)', 'avkastning (på noe)'],
    ['tIl', 'salivate', 'å sikle'],
    ['tuS', 'cough', 'hoste'],
    ["tuy'", 'to spit', 'å spytte'],
    ['tlhot', 'to land on water', 'tå lande på vann'],
    ['tlhov', 'wheeze', 'hvese'],
    ['tlhup', 'whisper', 'hviske'],
    ['vaD', 'be flexible', 'være fleksibel'],
    ['van', 'to end', 'å ende'],
    ['vegh', 'go through', 'gå gjennom'],
    ['wogh', 'do more than is acceptable', 'ikke mer enn det som er akseptabelt'],
    ['wom', 'to peck', 'å hakke'],
    ['wuD', 'to snore', 'å snorke'],
    ['yeq', 'to cooperate', 'å samarbeide'],
    ['yIQ', 'to be wet', 'å bli våt'],
    ['em', 'to vomit', 'å spy'],
    ['Iq', 'be too much, be too many', 'være for mye, være for mange'],
    ['ur', 'treason ', 'forræderi'],
    ["naw'", 'access', 'adgang'],
    ['yem', 'sin', 'synd'],
    ['choq', 'save', 'spare'],
    ['gher', 'pull together', 'trekke sammen'],
    ["je'", 'feed someone', 'mate noen'],
    ['magh', 'to betray', 'å forråde'],
    ['natlh', 'drain', 'drenere'],
    ['Qap', 'to win', 'å vinne'],
    ['Qoy', 'to hear', 'å høre'],
    ['Soq', 'to gain', 'å oppnå'],
    ['tagh', 'begin a process', 'starte en prosess'],
    ['tlhej', 'to accompany', 'å følge']
];
