export default [
    ['Baboochka', 'Old woman', 'Gammel dame'],
    ['Baddiwad', 'Bad', 'Dårlig'],
    ['Bezoomy', 'Mad', 'Mad'],
    ['Biblio', 'Library', 'Bibliotek'],
    ['Bitva', 'Battle', 'Battle'],
    ['Bog', 'God', 'Gud'],
    ['Bolnoy', 'Sick', 'Syk'],
    ['Bolshy', 'Big', 'Stor'],
    ['Bratchny', 'Bastard', 'Bastard'],
    ['Bratty', 'Brother', 'Brother'],
    ['Britva', 'Razor', 'Barberhøvel'],
    ['Brooko', 'Belly', 'Mage'],
    ['Brosay', 'Throw', 'Å kaste'],
    ['Bugatty', 'Rich', 'Rich'],
    ['Cal', 'Shit', 'Dritt'],
    ['Cancer', 'Cigarette', 'Sigarett'],
    ['Cantora', 'Office', 'Kontor'],
    ['Carman', 'Pocket', 'Pocket'],
    ['Chai', 'Tea', 'Te'],
    ['Charlie', 'Priest', 'Priest'],
    ['Chasha', 'Cup', 'Kopp'],
    ['Chasso', 'Guard', 'Guard'],
    ['Cheena', 'Woman', 'Kvinne'],
    ['Cheest', 'Wash', 'Vask'],
    ['Chelloveck', 'Fellow', 'Fellow'],
    ['Chepooka', 'Nonsense', 'Nonsense'],
    ['Choodessny', 'Wonderful', 'Fantastisk'],
    ['Clop', 'To knock', 'Å banke'],
    ['Cluve', 'Beak', 'Nebb'],
    ['Collocol', 'Bell', 'Bjelle'],
    ['Crast', 'To steal', 'Å stjele'],
    ['Creech', 'To scream', 'Å skrike'],
    ['Ded', 'Old man', 'Gammel mann'],
    ['Devotchka', 'Girl', 'Jente'],
    ['Dobby', 'Good', 'God'],
    ['Domy', 'House', 'Hus'],
    ['Dook', 'Ghost', 'Ghost'],
    ['Dorogoy', 'Valuable', 'Verdifull'],
    ['Drat', 'Fight', 'Slåsskamp'],
    ['Drencrom', 'Drugs', 'Dop'],
    ['Droog', 'Friend', 'Venn'],
    ['Dva', 'Two', 'To'],
    ['Eegra', 'Game', 'Spill'],
    ['Eemya', 'Name', 'Navn'],
    ['Eggiweg', 'Egg', 'Egg'],
    ['Em', 'Mum', 'Mamma'],
    ['Fagged', 'Tired', 'Trøtt'],
    ['Filly', 'Play', 'Spille'],
    ['Firegold', 'A drink', 'En drink'],
    ['Forella', 'Trout', 'Ørret'],
    ['Gazetta', 'Newspaper', 'Avis'],
    ['Glazz', 'Eye', 'Eye'],
    ['Gloopy', 'Stupid', 'Dum'],
    ['Godman', 'Priest', 'Priest'],
    ['Golly', 'Money', 'Penger'],
    ['Goloss', 'Voice', 'Voice'],
    ['Goober', 'Lip', 'Leppe'],
    ['Gooly', 'To walk', 'Å gå'],
    ['Gorlo', 'Throat', 'Hals'],
    ['Govoreet', 'To speak', 'Å snakke'],
    ['Grazhny', 'Dirty', 'Dirty'],
    ['Gromky', 'Loud', 'Loud'],
    ['Groody', 'Breast', 'Bryst'],
    ['Gruppa', 'Group', 'Gruppe'],
    ['Guff', 'To laugh', 'Å le '],
    ['Gulliver', 'Head', 'Hode'],
    ['Guttiwuts', 'Guts', 'Guts'],
    ['Hen-korm', 'Chickenfeed', 'Kyllingfôr'],
    ['Horn', 'To Cry Out', 'Å skrike ut'],
    ['Horrorshow', 'Good', 'God'],
    ['Interessovat', 'To interest', 'Å interessere'],
    ['Itty', 'To go', 'Å gå'],
    ['Jammiwam', 'Jam', 'Syltetøy'],
    ['Jeezny', 'Life', 'Livet'],
    ['Kartoffel', 'Potatoes', 'Poteter'],
    ['Keeshkas', 'Guts', 'Guts'],
    ['Kleb', 'Bread', 'Brød'],
    ['Klootch', 'Key', 'Nøkkel'],
    ['Knopka', 'Button', 'Knapp'],
    ['Kopat', 'To dig', 'Å grave'],
    ['Koshka', 'Cat', 'Katt'],
    ['Kot', 'Tomcat', 'Tomcat'],
    ['Krovvy', 'Blood', 'Blod'],
    ['Kupet', 'To Buy', 'Å kjøpe'],
    ['Lapa', 'Paw', 'Pote '],
    ['Lewdies', 'People', 'Mennesker'],
    ['Litso', 'Face', 'Ansikt'],
    ['Lomtick', 'Slice', 'Skjære'],
    ['Loveted', 'Caught', 'Fanget'],
    ['Lubbilubbing', 'Making love', 'Å elske'],
    ['Luscious glory', 'Hair', 'Hår'],
    ['Malchick', 'Boy', 'Gutt'],
    ['Malenky', 'Little', 'Litt'],
    ['Maslo', 'Butter', 'Smør'],
    ['Merzky', 'Filthy', 'Filthy'],
    ['Messel', 'Thought', 'Trodde'],
    ['Mesto', 'Place', 'Sted'],
    ['Millicent', 'Policeman', 'Politimann'],
    ['Minoota', 'Minute', 'Minutt'],
    ['Molodoy', 'Young', 'Young'],
    ['Moloko', 'Milk', 'Melk'],
    ['Moodge', 'Man', 'Man'],
    ['Mounch', 'Snack', 'Matbit'],
    ['Mozg', 'Brain', 'Hjerne'],
    ['Nachinat', 'To begin', 'Å begynne'],
    ['Nadmenny', 'Arrogant', 'Arrogant'],
    ['Nadsat', 'Teenage', 'Teenage'],
    ['Nagoy', 'Naked', 'Naked'],
    ['Nazz', 'Fool', 'Fool'],
    ['Neezhnies', 'Underpants', 'Underbukser'],
    ['Nochy', 'Night', 'Natt'],
    ['Noga', 'Foot', 'Fot'],
    ['Nozh', 'Knife', 'Kniv'],
    ['Nukig', 'Smelling', 'Lukt'],
    ['Oddy-knocky', 'Lonesome', 'Ensom'],
    ['Odin', 'One', 'En'],
    ['Okno', 'Window', 'Vindu'],
    ['Oobivat', 'To kill', 'Å drepe'],
    ['Ookadeet', 'To leave', 'Å forlate'],
    ['Ooko', 'Ear', 'Øre'],
    ['Oomny', 'Clever', 'Klok'],
    ['Oozhassny', 'Terrible', 'Forferdelig'],
    ['Oozy', 'Chain', 'Kjede'],
    ['Orange', 'Man', 'Mann'],
    ['Osoosh', 'To dry', 'Å tørke'],
    ['Otchkies', 'Eyeglasses', 'Briller'],
    ['Pan-handle', 'Erection', 'Ereksjon'],
    ['Pee', 'Father', 'Far'],
    ['Peet', 'To drink', 'Å drikke'],
    ['Pishcha', 'Food', 'Mat'],
    ['Platch', 'To cry  ', 'Å gråte'],
    ['Platties', 'Clothes', 'Klær'],
    ['Plenny', 'Prisoner', 'Fange '],
    ['Plesk', 'Splash', 'Splash'],
    ['Pletcho', 'Shoulder', 'Skulder'],
    ['Plott', 'Flesh', 'Flesk'],
    ['Podooshka', 'Pillow', 'Pute'],
    ['Pol', 'Sex', 'Kjønn'],
    ['Polezny', 'Useful', 'Nyttig'],
    ['Polyclef', 'Skeleton key', 'Skjelettnøkkel'],
    ['Pony', 'To understand', 'Å forstå'],
    ['Poogly', 'Scared', 'Redd'],
    ['Pooshka', 'Gun', 'Våpen'],
    ['Pop-disk', 'Pop-music disc', 'Pop-musikk-plate'],
    ['Prestoopnik', 'Criminal', 'Kriminell'],
    ['Pretty Polly', 'Money', 'Penger'],
    ['Privodeet', 'To lead somewhere', 'Å føre et sted'],
    ['Prod', 'To produce', 'Å produsere'],
    ['Ptitsa', 'Girl', 'Jente'],
    ['Pyahnitsa', 'Drunk', 'Full'],
    ['Rabbit', 'Work', 'Arbeid'],
    ['Radosty', 'Joy', 'Joy'],
    ['Raskazz', 'Story', 'Historie'],
    ['Rasoodock', 'Mind', 'Sinn'],
    ['Raz', 'Time', 'Tid'],
    ['Razdrez', 'Upset', 'Urolig'],
    ['Razrez', 'To Rip', 'Å revne'],
    ['Rooker', 'Hand', 'Hånd'],
    ['Rot', 'Mouth', 'Munn'],
    ['Rozz', 'Policeman', 'Politimann'],
    ['Sabog', 'Shoe', 'Sko'],
    ['Sakar', 'Sugar', 'Sukker'],
    ['Sammy', 'Generous', 'Generøs'],
    ['Sarky', 'Sarcastic', 'Sarkastisk'],
    ['Scoteena', 'Cow', 'Ku'],
    ['Shaika', 'Gang', 'Gjeng'],
    ['Sharp', 'Female', 'Kvinne'],
    ['Sharries', 'Balls', 'Baller'],
    ['Shest', 'Pole', 'Stang'],
    ['Shilarny', 'Concern', 'Bekymring'],
    ['Shive', 'To cut', 'Å kutte'],
    ['Shiyah', 'Neck', 'Hals'],
    ['Shlaga', 'Club', 'Klubbe'],
    ['Shlapa', 'Hat', 'Hatt'],
    ['Shlem', 'Helmet', 'Hjelm'],
    ['Shoom', 'Noise', 'Støy'],
    ['Shoot', 'Fool', 'Dust'],
    ['Sinny', 'Movie', 'Film'],
    ['Skazat', 'To say', 'Å si'],
    ['Skolliwoll', 'School', 'Skole'],
    ['Skorry', 'Quick', 'Hurtig'],
    ['Skriking', 'Scratching', 'Å skrape'],
    ['Skvat', 'To grab', 'Å hente'],
    ['Sladky', 'Sweet', 'Søt'],
    ['Sloochat', 'To happen', 'Å skje'],
    ['Slooshy', 'To listen', 'Å høre'],
    ['Slovo', 'Word', 'Ord'],
    ['Smot', 'To look', 'Å se'],
    ['Sneety', 'Dream', 'Drømme'],
    ['Snoutie', 'Tobacco', 'Tobakk'],
    ['Snuff It', 'To die', 'Å dø'],
    ['Sobirat', 'Pick up', 'Plukke opp'],
    ['Sod', 'Bastard', 'Bastard'],
    ['Sodding', 'Fucking', 'Jævla'],
    ['Soomka', 'Woman', 'Kvinne'],
    ['Soviet', 'Advice', 'Råd'],
    ['Spat, spatchka', 'Sleep', 'Søvn'],
    ['Spoogy', 'Terrified', 'Livredd'],
    ['Staja', 'State jail', 'Stasfengsel'],
    ['Starry', 'Old  ', 'Gammel'],
    ['Strack', 'Horror', 'Redsel'],
    ['Synthmesc', 'A drug', 'Et stoff'],
    ['Tally', 'Waist', 'Midje'],
    ['Tashtook', 'Handkerchief', 'Lommetørkle'],
    ['Tass', 'Cup', 'Kopp'],
    ['Tolchock', 'To hit', 'Å slå'],
    ['Toofles', 'Slippers', 'Tøfler'],
    ['Tree', 'Three', 'Tre'],
    ['Vareet', 'To cook up', 'Å koke opp'],
    ['Vaysay', 'Toilet', 'Toalett'],
    ['Veck', 'Guy', 'En fyr'],
    ['Vellocet', 'A particular drug', 'Et bestemt legemiddel'],
    ['Veshch', 'Thing', 'Ting'],
    ['Viddy', 'To see', 'Å se'],
    ['Voloss', 'Hair', 'Hår'],
    ['Von', 'Smell', 'Lukt'],
    ['Vred', 'To harm', 'Å skade'],
    ['Warble', 'Song', 'Sang'],
    ['Yahma', 'Hole', 'Hull'],
    ['Yahoody', 'Jew', 'Jøde'],
    ['Yahzick', 'Tongue', 'Tunge'],
    ['Yarbles', 'Testicles', 'Testikler'],
    ['Yeckate', 'To drive', 'Å kjøre'],
    ['Zammechat', 'Remarkable', 'Bemerkelsesverdig'],
    ['Zasnoot', 'To sleep', 'Å sove'],
    ['Zheena', 'Wife', 'Kone'],
    ['Zoobies', 'Teeth', 'Tenner'],
    ['Zvonock', 'Doorbell', 'Ringeklokke'],
    ['Zvook', 'Sound', 'Lyd']
];
