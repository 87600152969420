<template>
  <div class="MemorizeItemInstruction">
    <!-- MemorizeItemInstruction -->
    <div
      class="instruction"
      :style="{ color: theme.primaryColor }">
      {{ $t('instruction') }}
    </div>
  </div>
</template>

<translations>
  instruction: 'Memorize'
  instruction_no: 'Memorer'
</translations>

<script>
export default {
    props: [],
    inject: ['theme']
};
</script>

<style scoped lang="scss">
.MemorizeItemInstruction {
    // border: 1px solid green;
    // padding: 1em;
}
.instruction {
    font-size: 130%;
}
</style>
