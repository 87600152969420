import shuffle from 'lodash-es/shuffle';

import store from '@/store';
import { splitString, getRandomInt, pickUniqueRandomItemsWithMemory } from '@/modules/memogenius-game/utils';

import nadsat from './data/nadsat';
import elvish from './data/elvish';
import klingon from './data/klingon';
import navi from './data/navi';

const languageMap = {
    nadsat,
    elvish,
    klingon,
    navi
};

const sourceLanguageMap = {
    en: {
        id: 'english',
        index: 1
    },
    no: {
        id: 'norwegian',
        index: 2
    }
};

class QuestionService {
    getItems(numberOfItems, { language }) {
        const lang = sourceLanguageMap[store.getters['moduleApp/locale']];
        const state = store.state.moduleGameVocabguru.questionServiceState;
        const usedMap = JSON.parse(JSON.stringify(state.usedMap || {}));
        let items = [];

        pickUniqueRandomItemsWithMemory(languageMap[language], numberOfItems, {
            used: usedMap[language],
            minimumAvailable: numberOfItems + 30,
            persist(used) {
                usedMap[language] = used;
                store.dispatch('moduleGameVocabguru/updateQuestionServiceState', { usedMap });
            }
        }).forEach(sourceItem => {
            items.push({
                a: {
                    language,
                    word: sourceItem[0]
                },
                b: {
                    language: lang.id,
                    word: sourceItem[lang.index]
                }
            });
        });
        return items;
    }

    getWordFragments(numberOfFragments, include = []) {
        const fragments = [];
        const seen = {};

        // NOTE: fragments from 'include' does not have to be unique. Example: 'finfin' (no), where we need both parts to form the answer
        include.forEach(word => {
            const [part1, part2] = splitString(word).map(w => w.toLowerCase());
            fragments.push(part1);
            seen[part1] = true;
            fragments.push(part2);
            seen[part2] = true;
        });

        while (fragments.length < numberOfFragments) {
            const word = nadsat[getRandomInt(0, nadsat.length)][0];
            const [part1, part2] = splitString(word).map(w => w.toLowerCase());
            if (!seen[part1]) {
                fragments.push(part1);
                seen[part1] = true;
            }
            if (fragments.length === numberOfFragments) {
                break;
            }
            if (!seen[part2]) {
                fragments.push(part2);
                seen[part2] = true;
            }
        }

        return shuffle(fragments);
    }
}

const questionService = new QuestionService();
export default questionService;
