function generateLevel(numberOfItems, itemMemorizeTimeLimit, itemQuizTimeLimit, language, stars2, stars3) {
    return {
        numberOfItems,
        itemMemorizeTimeLimit,
        itemQuizTimeLimit,
        language,
        stars2,
        stars3
    };
}
const rawLevelOptions = [
    [3, 12, 12, 'nadsat', 9, 6], // 1.1
    [5, 12, 12, 'nadsat', 9, 6], // 1.2

    [10, 12, 12, 'elvish', 9, 6], // 2.1
    [15, 12, 12, 'elvish', 9, 6], // 2.3
    [20, 12, 12, 'elvish', 9, 6], // 2.4

    [20, 12, 12, 'klingon', 9, 6], // 3.1
    [25, 12, 12, 'klingon', 9, 6], // 3.2
    [30, 12, 12, 'klingon', 9, 6], // 3.3

    [35, 12, 12, 'navi', 9, 6], // 4.1
    [40, 12, 12, 'navi', 9, 6], // 4.2
    [45, 12, 12, 'navi', 9, 6], // 4.3
    [50, 12, 12, 'navi', 9, 6] // 4.4
];

export default function getLevelOptions(levelIndex) {
    return generateLevel(...rawLevelOptions[levelIndex]);
}
