<template>
  <div class="Question">
    <div
      class="question"
      :style="{ color: theme.primaryColor }">
      {{ $t('currentGame.Question.question') }}
    </div>

    <div>
      <div class="MiniCard">
        <div class="MiniCard__label">
          {{ $t(`currentGame.${question.b.language}`) }}
        </div>
        <div class="MiniCard__word">
          {{ question.b.word }}
        </div>
        <div class="MiniCard__divider" />
        <div class="MiniCard__label">
          {{ $t(`currentGame.${question.a.language}`) }}
        </div>
        <div class="MiniCard__word">
          ????
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import last from 'lodash-es/last';

export default {
    props: {
        itemIndex: {
            type: Number,
            required: true
        }
    },
    inject: ['module', 'theme'],
    computed: {
        doneMap() {
            const map = {};
            this.quizItem.tests.forEach(t => {
                map[t.name] = t.score;
            });
            return map;
        },
        session() {
            return this.module.state;
        },
        quizItem() {
            return this.module.getters('quiz/getItem')(this.itemIndex);
        },
        currentTestName() {
            return last(this.quizItem.tests).name;
        },
        question() {
            return this.session.quiz.sourceItems[this.quizItem.sourceItemIndex].data;
        }
    }
};
</script>

<style scoped lang="scss">
.Question {
    padding: 1em;
    // border: 1px solid red;
}

.question {
    font-size: 120%;
    text-align: center;
}
.MiniCard {
    background-color: white;
    color: black;
    padding: 1em;
    $size: 10em;
    // width: $size;
    // height: $size;
    display: flex;
    flex-direction: column;
    text-align: center;

    margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;
}

// .MiniCard__number {
//   font-size: 140%;
// }
.MiniCard__divider {
    height: 1px;
    background-color: rgba(black, 0.05);
    margin: 1em 0;
}
// .MiniCard__spacer {
//   flex: 1;
// }
// .MiniCard__text {
//   line-height: 1.5em;
//   color: mix(black, white, 80%);
// }

// .MiniCard__text, .MiniCard__number {
//   &:not(.done) {
//     // opacity: 0;
//   }
// }
.MiniCard__label {
    color: rgba(black, 0.4);
    margin-bottom: 1em;
}
.MiniCard__word {
    line-height: 1.5em;
    color: mix(black, white, 80%);
    font-size: 120%;
}
</style>
